import React, { Fragment, useState, useEffect, useCallback } from 'react';
import usePrevious from '../../../utils/usePrevious';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/Buttons';
import OrderSettingSummaryContainer from '../../OrderSetting/OrderSettingSummary/OrderSettingSummaryContainer';
import { navigate } from 'gatsby';
import AddTipContainer from '../AddTip/AddTipContainer';
import SpecialInstructionsContainer from '../SpecialInstructions/SpecialInstructionsContainer';
import PriceBreakdown from './PriceBreakdown';
import CartPaymentMethodContainer from '../CartPaymentMethod/CartPaymentMethodContainer';
import { Title1, Caption1, Title3 } from '../../../components/Typography';
import CartActivatedOffersContainer from '../../CartActivatedOffers/CartActivatedOffersContainer';
import appConfig from '../../../../app-config';
import OrderSummaryCartContainer from '../../OrderSummaryCart/OrderSummaryCartContainer';
import UpsellContainer from '../../Upsell/UpsellContainer';
import FoodRequiredModal from './FoodRequiredModal';
import useIsFoodRequired from '../../../customHooks/useIsFoodRequired';
import { PICKUP } from '../../OrderSetting/orderSettingTypes';
import useCart from '../../../customHooks/useCart';
import { connect } from 'react-redux';
const CartWrapper = styled.div`
  display: grid;
  grid-gap: 28px;
  margin: 0;
  padding: 0 15px 0 15px;
  @media (max-width: 786px) {
    margin-top: 10px;
  }
  justify-content: center;
`;

const Card = styled.div`
  display: grid;
  grid-gap: 24px;
  // background-color: #ffffff;
  width: 750px;
  padding-top: 15px;
  margin: auto;
  @media (max-width: 786px) {
    width: 100%;
    // width: unset;
    max-width: 650px;
    margin-top: 30px;
  }
`;

const PriceBreakdownWrapper = styled.div`
  width: 750px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 786px) {
    width: 100%;
    max-width: 650px;
  }
`;

const CheckoutRow = styled.div`
  width: 750px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  @media (max-width: 480px) {
    width: 100%;
    grid-auto-flow: row;
    justify-content: stretch;
    grid-gap: 28px;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 350px;
    grid-auto-flow: row;
    justify-content: stretch;
    grid-gap: 28px;
  }
`;

const BackButton = styled(Caption1)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02px;
  margin-bottom: 30px;
  &:hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }

  @media (max-width: 786px) {
    grid-row: 2;
    text-align: center;
  }
`;

const CheckoutButton = styled(PrimaryButton)`
  margin-bottom: 30px;
  @media (max-width: 786px) {
    margin-right: 0;
  }
`;

const Hr = styled.hr`
  background-color: white;
  border: none;
  border-top: dashed 0.11em ${(props) => props.theme.reviewHrColor};
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  margin-right: auto;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const TipCardWrapper = styled(Card)`
  margin-top: 15px;
`;

const Checkout = ({
  validatedCart,
  invalidateCart,
  handlePlaceOrder,
  paymentMethods,
  requestingPlaceOrder,
  requestingValidateCart,
  orderSettingReducer,
  tipPercentage,
  locationsReducer,
  productsInCart,
  is_tip_enabled,
  cartScreen,
  updateCartScreen,
  errors,
  resetCartErrorMessages,
  selectedPaymentMethod,
  isPayOnArrivalSelected,
}) => {
  const [isFoodRequiredModalOpen, setIsFoodRequiredModalOpen] = useState(false);
  const prevValidatedCart = usePrevious(validatedCart);
  const { isFoodRequired } = useIsFoodRequired(productsInCart);
  const { interOrderType } = orderSettingReducer;
  const isAddTipHidden =
    appConfig.appSource === 'mary_browns' && interOrderType === PICKUP
      ? true
      : false;

  const backToReview = () => {
    invalidateCart();
    updateCartScreen('review');
  };
  const checkMethodPayment = useCallback(() => {
    resetCartErrorMessages();
  }, [paymentMethods]);
  useEffect(() => {
    checkMethodPayment();
  }, [checkMethodPayment]);
  useEffect(() => {
    if (!requestingValidateCart && prevValidatedCart === null) {
      window.scrollTo(0, 0);
    }
  }, [requestingValidateCart]);
  const renderButtons = () => {
    if (validatedCart) {
      return (
        <Fragment>
          <BackButton id="Review-BackOrderReview" onClick={backToReview}>
            <i
              className="fal fa-chevron-left"
              style={{ fontWeight: '600' }}
            ></i>{' '}
            Back To Order Review
          </BackButton>
          <CheckoutButton
            id="Review-PlaceOrder"
            disabled={
              (selectedPaymentMethod !== 'poa' && !paymentMethods?.length) ||
              requestingPlaceOrder ||
              requestingValidateCart
            }
            onClick={handlePlaceOrder}
          >
            Place Order
          </CheckoutButton>
        </Fragment>
      );
    }
  };

  return (
    <CartWrapper>
      <Card>
        <Title1
          textTransform={appConfig.styleConfig.reviewCheckoutPageTitle}
          style={{ marginBottom: '32px' }}
        >
          {cartScreen === 'checkout' ? 'Checkout' : 'Review Your Order'}
        </Title1>
        <OrderSettingSummaryContainer notEditable={true} isForReviewPage />
      </Card>
      <Hr />
      <Card>
        <OrderSummaryCartContainer isFromReviewPage={true} />
      </Card>
      <Hr />
      {cartScreen === 'checkout' &&
        validatedCart &&
        is_tip_enabled &&
        !isAddTipHidden &&
        !isPayOnArrivalSelected && (
          <Fragment>
            <div>
              <TipCardWrapper>
                <AddTipContainer
                  isForCheckoutPage={true}
                  tipPercentage={tipPercentage}
                  isAddTipHidden={isAddTipHidden}
                />
              </TipCardWrapper>
            </div>

            <Hr />
          </Fragment>
        )}
      {cartScreen === 'checkout' && validatedCart && (
        <Fragment>
          <PriceBreakdownWrapper>
            <PriceBreakdown
              validatedCart={validatedCart}
              orderSettingReducer={orderSettingReducer}
              locationsReducer={locationsReducer}
              is_tip_enabled={is_tip_enabled}
              isAddTipHidden={isAddTipHidden}
              isPayOnArrivalSelected={isPayOnArrivalSelected}
            />
          </PriceBreakdownWrapper>
          <Hr />
          <Card>
            <CartPaymentMethodContainer isForCheckoutPage={true} />
          </Card>
        </Fragment>
      )}
      <CheckoutRow>{renderButtons()}</CheckoutRow>
      <FoodRequiredModal
        open={isFoodRequiredModalOpen}
        close={() => setIsFoodRequiredModalOpen(false)}
      />
    </CartWrapper>
  );
};

const mapStateToProps = (state) => ({
  isPayOnArrivalSelected: state.paymentMethodsReducer.isPayOnArrivalSelected,
});

export default connect(mapStateToProps)(Checkout);
