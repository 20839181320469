import React from 'react';
import {connect} from 'react-redux';
import {changeTipPercentage, resetInvalidTip} from '../cartActions';
import AddTip from './AddTip';
import appConfig from '../../../../app-config';
import {DELIVERY} from '../../OrderSetting/orderSettingTypes';

const AddTipContainer = ({
  tipPercentage,
  changeTipPercentage,
  resetInvalidTip,
  userInputInvalidTip,
  isForCheckoutPage,
  guestCheckoutPage,
}) => {

  return (
    <AddTip
      tipPercentage={tipPercentage}
      changeTipPercentage={changeTipPercentage}
      resetInvalidTip={resetInvalidTip}
      userInputInvalidTip={userInputInvalidTip}
      isForCheckoutPage={isForCheckoutPage}
      guestCheckoutPage={guestCheckoutPage}
    />
  );
};

const mapStateToProps = state => ({
  tipPercentage: state.cartReducer.tipPercentage,
  userInputInvalidTip: state.cartReducer.userInputInvalidTip,
});

export default connect(mapStateToProps, {changeTipPercentage, resetInvalidTip})(
  AddTipContainer
);
