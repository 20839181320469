import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { navigate } from 'gatsby';

import FormGrid from '../../../../components/Form/FormGrid';
import { PrimaryButton } from '../../../../components/Buttons';
import { Caption1, Title3 } from '../../../../components/Typography';
import PhoneNumber from '../../../../layout/components/PhoneNumber';
const Wrapper = styled.div`
  display: grid;
  grid-gap: 90px;
  grid-template-columns: auto 1fr;
  max-width: 670px;

  @media (max-width: 786px) {
    grid-gap: 24px;
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
  }
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  :after {
    content: '';
    display: block;
    width: calc(100% + 1rem);
    padding-top: 5px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const PersonalDetailsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;

  @media (max-width: 786px) {
    max-width: 480px;
  }
`;

const FormLayout = styled(FormGrid)`
  grid-gap: 66px;
  max-width: 670px;
`;

const PhoneNumberText = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const CheckoutRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    display: block;
  }

  @media (min-width: 480px) and (max-width: 786px) {
    width: 350px;
    display: block;
  }
`;

const ButtonWrapper = styled.div`
  text-align: ${(props) => props.align};
  padding: 10px 0;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    padding: 10px 0;
    display: block;
  }
`;

const BackButton = styled(Caption1)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02px;

  :hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }

  @media (max-width: 786px) {
    grid-row: 2;
    text-align: center;
  }
`;

const ContinueButton = styled(PrimaryButton)`
  display: inline-block;
  margin: 0 auto;
  @media (max-width: 786px) {
    margin-right: 0;
  }
`;

const Hr = styled.hr`
  border-top: solid 1px ${(props) => props.theme.guestCheckoutHrColor};
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});
const PersonalDetailsForm = ({
  classes,
  initiateCheckoutAsGuest,
  guestPersonalDetails,
  setGuestPersonalDetails,
  setShowGuestPersonalDetailsPage,
  showGuestPersonalDetailsPage,
  setScreenToShow,
  authReducer,
}) => {
  const deepLink = localStorage.getItem('deep-link-url');
  const countries = [
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
  ];
  const isEmail = new RegExp(
    '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
      '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
  );
  const { isGuestSessionInitiated } = authReducer;
  useEffect(() => {
    if (showGuestPersonalDetailsPage) {
      setShowGuestPersonalDetailsPage(false);
    }
  }, [showGuestPersonalDetailsPage]);
  return (
    <Formik
      initialValues={guestPersonalDetails}
      validate={(values) => {
        let errors = {};
        if (!values.first_name) {
          errors.name = 'Required';
        }
        if (!values.last_name) {
          errors.name = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        } else if (!isEmail.test(values.email)) {
          errors.email = 'Please put a valid email format';
        }
        if (!values.phone_number) {
          errors.phone_number = 'Required';
        } else if (values.phone_number.length > 10) {
          errors.phone_number = 'No more than 10 digits allowed';
        } else if (values.phone_number.length < 10) {
          errors.phone_number = 'Minimum 10 digits allowed';
        }
        return errors;
      }}
      onSubmit={(values) => {
        setGuestPersonalDetails(values);
        initiateCheckoutAsGuest(values);
        setScreenToShow('payment-details');
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormLayout>
            <Wrapper>
              <Title3Wrapper id="PersonalDetailsForm-Title">
                Personal <br /> Details
              </Title3Wrapper>
              <PersonalDetailsWrapper>
                <TextField
                  id="PersonalDetailsForm-FirstName"
                  label="First Name"
                  name="first_name"
                  type="text"
                  autoComplete="given-name"
                  value={values.first_name}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.first_name && errors.first_name ? true : false}
                  helperText={touched.first_name && errors.first_name}
                  InputLabelProps={{
                    className: classes.input,
                    required: false,
                  }}
                  // disabled={isGuestSessionInitiated}
                />
                <TextField
                  id="PersonalDetailsForm-LastName"
                  label="Last Name"
                  name="last_name"
                  type="text"
                  autoComplete="family-name"
                  value={values.last_name}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.last_name && errors.last_name ? true : false}
                  helperText={touched.last_name && errors.last_name}
                  InputLabelProps={{
                    className: classes.input,
                    required: false,
                  }}
                  // disabled={isGuestSessionInitiated}
                />
                <TextField
                  id="PersonalDetailsForm-Email"
                  label="Email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.email && errors.email ? true : false}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{
                    className: classes.input,
                    required: false,
                  }}
                  // disabled={isGuestSessionInitiated}
                />
                <PhoneNumberText>
                  <PhoneNumber
                    countries={countries}
                    name="phone_number"
                    label="Phone Number"
                    value={values.phone_number}
                    id="RegistrationForm-PhoneNumberField"
                    setFieldValue={setFieldValue}
                    InputProps={{
                      className: classes.input,
                    }}
                    handlePhone={(value) => {
                      setFieldValue('phone_number', value);
                    }}
                    handleBlur={handleBlur}
                    required
                    error={touched.phone_number && errors.phone_number}
                    InputLabelProps={{
                      className: classes.input,
                      required: false,
                    }}
                    helperText={errors.phone_number}
                    // disabled={isGuestSessionInitiated}
                  />
                </PhoneNumberText>
              </PersonalDetailsWrapper>
            </Wrapper>
            <CheckoutRow>
              <ButtonWrapper align="left">
                <BackButton
                  id="GuestCheckout-BackToMenu"
                  onClick={() => {
                    if (deepLink) {
                      navigate('/' + deepLink);
                    } else {
                      navigate('/');
                    }
                  }}
                >
                  <i
                    className="fal fa-chevron-left"
                    style={{ fontWeight: '600' }}
                  ></i>{' '}
                  Back To Menu
                </BackButton>
              </ButtonWrapper>
              <ButtonWrapper align="right">
                <ContinueButton id="GuestCheckout-Continue" type="submit">
                  Continue
                </ContinueButton>
              </ButtonWrapper>
            </CheckoutRow>
          </FormLayout>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(PersonalDetailsForm);
