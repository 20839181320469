import React, { Fragment, useState, useEffect, useCallback } from 'react';
import usePrevious from '../../../utils/usePrevious';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/Buttons';
import OrderSettingSummaryContainer from '../../OrderSetting/OrderSettingSummary/OrderSettingSummaryContainer';
import { navigate } from 'gatsby';
import SpecialInstructionsContainer from '../SpecialInstructions/SpecialInstructionsContainer';
import { Title1, Caption1, Title3 } from '../../../components/Typography';
import CartActivatedOffersContainer from '../../CartActivatedOffers/CartActivatedOffersContainer';
import appConfig from '../../../../app-config';
import OrderSummaryCartContainer from '../../OrderSummaryCart/OrderSummaryCartContainer';
import UpsellContainer from '../../Upsell/UpsellContainer';
import FoodRequiredModal from './FoodRequiredModal';
import useIsFoodRequired from '../../../customHooks/useIsFoodRequired';

const CartWrapper = styled.div`
  display: grid;
  grid-gap: 28px;
  margin: 0;
  padding: 0 15px 0 15px;
  @media (max-width: 786px) {
    margin-top: 10px;
  }
  justify-content: center;
`;

const Card = styled.div`
  display: grid;
  width: 750px;
  padding-top: 15px;
  margin: 0;
  @media (max-width: 786px) {
    width: 100%;
    max-width: 650px;
    margin-top: 30px;
  }
`;

const PriceBreakdownWrapper = styled.div`
  width: 750px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 786px) {
    width: 100%;
    max-width: 650px;
  }
`;

const CheckoutRow = styled.div`
  width: 750px;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  @media (max-width: 480px) {
    width: 350px;
    display: block;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: ${(props) => props.align || 'left'};
  /* background-color: ${(props) => props.backColor}; */
  margin-bottom:15px;
  @media (max-width: 786px) {
    text-align: center;
  }
`;
const BackButton = styled(Caption1)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02px;
  width: 100%;
  align-self: start;
  :hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }
  @media (max-width: 786px) {
    grid-row: 2;
    align-self: center;
  }
`;
const CheckoutButton = styled(PrimaryButton)`
  display: inline-block;
  @media (max-width: 786px) {
    margin-right: 0;
    max-width: 300px;
  }
`;

const Hr = styled.hr`
  background-color: white;
  border: none;
  border-top: dashed 0.11em ${(props) => props.theme.reviewHrColor};
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  margin-right: auto;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const TipCardWrapper = styled(Card)`
  margin-top: 15px;
`;

const Review = ({
  validatedCart,
  handleValidateCart,
  invalidateCart,
  handlePlaceOrder,
  paymentMethods,
  disableCheckoutButton,
  isAuthenticated,
  setAuthenticationModalIsVisible,
  requestingPlaceOrder,
  requestingValidateCart,
  orderSettingReducer,
  reviewScreenOffers,
  tipPercentage,
  productsInCart,
  setCheckoutAsGuestButtonIsVisible,
  locationsReducer,
  createAlert,
  is_tip_enabled,
  cartScreen,
  updateCartScreen,
  errors,
}) => {
  const deepLink = localStorage.getItem('deep-link-url');
  const [isFoodRequiredModalOpen, setIsFoodRequiredModalOpen] = useState(false);
  const [cartHasOnlyAlcohol, setCartHasOnlyAlcohol] = useState(isFoodRequired);
  const prevValidatedCart = usePrevious(validatedCart);
  const { isFoodRequired } = useIsFoodRequired(productsInCart);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (cartScreen === 'review') {
      window.scrollTo(0, 0);
    }
  }, [cartScreen]);

  const updateIsDisabled = useCallback(() => {
    if (errors && Object.keys(errors).length > 0) {
      setIsDisabled(false);
    }
  }, [errors]);

  useEffect(() => {
    updateIsDisabled();
  }, [updateIsDisabled]);

  const checkout = () => {
    if (isAuthenticated) {
      handleValidateCart();
    } else {
      setAuthenticationModalIsVisible(true);
    }
  };

  const handleCheckoutClick = () => {
    if (isFoodRequired) {
      setIsFoodRequiredModalOpen(true);
    } else {
      checkout();
    }
  };

  return (
    <CartWrapper>
      <Card>
        <Title1
          textTransform={appConfig.styleConfig.reviewCheckoutPageTitle}
          style={{ marginBottom: '32px' }}
        >
          {cartScreen === 'checkout' ? 'Checkout' : 'Review Your Order'}
        </Title1>
        <OrderSettingSummaryContainer notEditable={false} isForReviewPage />
      </Card>
      {!disableCheckoutButton &&
        (appConfig.isOffersEnabled ||
          appConfig.appSource === 'madradish' ||
          appConfig.appSource === 'madradish_catering') &&
        !!reviewScreenOffers.length &&
        isAuthenticated && <Hr />}
      {cartScreen === 'review' &&
        (appConfig.isOffersEnabled ||
          appConfig.appSource === 'madradish' ||
          appConfig.appSource === 'madradish_catering') &&
        isAuthenticated &&
        !!reviewScreenOffers.length &&
        !disableCheckoutButton && (
          <Card>
            <Title3Wrapper
              textTransform={appConfig.styleConfig.reviewCheckoutPageTitle}
            >
              {appConfig.appSource === 'madradish' ||
              appConfig.appSource === 'madradish_catering'
                ? 'Offers Available'
                : 'Reward Available'}
            </Title3Wrapper>
            <CartActivatedOffersContainer isFromReviewPage={true} />
          </Card>
        )}
      <Hr />
      <Card>
        <OrderSummaryCartContainer
          isFromReviewPage={true}
          setCartHasOnlyAlcohol={(bool) => setCartHasOnlyAlcohol(bool)}
        />
      </Card>
      <Hr />
      {appConfig.isOrderInstructionsEnabled ? (
        <>
          <Card>
            <SpecialInstructionsContainer isForReviewPage={true} />
          </Card>
          <Hr />
        </>
      ) : null}
      <CheckoutRow>
        <ButtonWrapper backColor="blue">
          <BackButton
            id="Review-BackToMenu"
            onClick={() => {
              if (deepLink) {
                navigate('/' + deepLink);
              } else {
                navigate('/');
              }
            }}
          >
            <i
              className="fal fa-chevron-left"
              style={{ fontWeight: '600' }}
            ></i>{' '}
            Back To Menu
          </BackButton>
        </ButtonWrapper>
        <ButtonWrapper align="right" backColor="red">
          <CheckoutButton
            id="Review-"
            onClick={handleCheckoutClick}
            disabled={disableCheckoutButton}
          >
            {isDisabled
              ? 'Loading...'
              : isFoodRequired
              ? 'Food is Required'
              : 'Checkout'}
          </CheckoutButton>
        </ButtonWrapper>
      </CheckoutRow>
      <FoodRequiredModal
        open={isFoodRequiredModalOpen}
        close={() => setIsFoodRequiredModalOpen(false)}
      />
    </CartWrapper>
  );
};

export default Review;
