import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Title1,
  Title2,
  Title3,
  Subhead,
  Caption1,
  Footnote,
  Body,
} from '../../../components/Typography';
import { navigate } from 'gatsby';
import appConfigFile from '../../../../app-config';
import {
  RESET_ORDER_SETTING_TIME_SETTINGS,
  DELIVERY,
  DROPOFF,
  PICKUP,
} from '../../OrderSetting/orderSettingTypes';
const OutWrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  text-align: center;
`;
const Wrapper = styled.div`
  border: 1px solid #d8d8d8;
  padding: 32px;
  border-radius: 16px;
  display: grid;
  grid-gap: 20px;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 12px;
  text-align: center;
`;

const CheckIcon = styled.i`
  color: ${(props) => props.theme.toggleColor};
  font-size: 64px;
  text-align: center;
  @media (max-width: 786px) {
    font-size: 48px;
  }
`;

const BackToHomeButton = styled(Subhead)`
  color: ${(props) => props.theme.bodyColor};
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }
`;

const OrderMethodType = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const Button = styled.button`
  font-weight: ${(props) => (props.secondary ? '400' : '600')};
  cursor: pointer;
  font-size: 15px;
  background: none;
  border: none;
  text-decoration: none;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  border-radius: 17.5px;
  padding: 7px 16px;
  width: 150px;
  margin: auto;
  color: ${(props) => props.theme.primaryButtonFontColor};
  background: ${(props) => props.theme.primaryButtonBackgroundColor};

  &:hover {
    background: ${(props) => props.theme.primaryButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const getAddress = (placedOrder) => {
  const { address } = placedOrder;
  if (placedOrder?.orderType === DELIVERY) {
    return `${address?.type ? `${address?.type} ` : ''}${
      address?.line2 ? `${address?.line2} -` : ''
    }${address?.line1}, ${address?.city}, ${address?.state}, ${address?.zip}`;
  } else if (placedOrder?.orderType === DROPOFF) {
    return `${address.map((selection) => {
      return ' ' + selection.value;
    })}`;
  } else {
    return `${address?.line1}, ${
      address?.line2 ? `${address?.line2},` : ''
    }    ${address?.city}, ${address?.state}`;
  }
};

const trackingId = 'UA-151239665-1';
const environment = process.env.GATSBY_ENV;

const SuccessfulOrder = ({
  userEmail,
  placedOrder,
  isOrderStatusEnabled,
  selectedChildLocation,
  orderDetails,
  parentLocation,
  isAuthenticated,
  orderSettingReducer,
  resetGuestPersonalDetails,
  resetGuestSession,
  resetGuestCreditCardDetails,
  resetGuestGiftCardDetails,
  appConfig,
  resetPayPalNonce,
  updateOrderSettingModalIsVisible,
  deliveryToAddress,
}) => {
  const deepLink = localStorage.getItem('deep-link-url');
  useEffect(() => {
    resetPayPalNonce();
    return () => {
      if (!isAuthenticated) {
        resetGuestPersonalDetails();
        resetGuestSession();
        resetGuestCreditCardDetails();
        resetGuestGiftCardDetails();
        // updateOrderSettingModalIsVisible(true);
      }
    };
  }, []);

  useEffect(() => {
    if (
      environment === 'production' &&
      appConfig.googleAnalyticsMeasurementId
    ) {
      window.gtag('config', appConfig.googleAnalyticsMeasurementId, {
        linker: {
          accept_incoming: true,
        },
      });
    }
    if (environment === 'production' && appConfig.googleAnalyticsTrackingId) {
      window.gtag('config', appConfig.googleAnalyticsTrackingId, {
        page_title: 'order-successful',
        page_path: '/order-successful',
      });
    }
  }, []);

  const {
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interOrderType,
  } = orderSettingReducer;

  const { name, line1, line2, city, state, type } =
    interOrderType === PICKUP && interPickUpDetails
      ? interPickUpDetails
      : interOrderType === DELIVERY && interDeliveryDetails
      ? interDeliveryDetails
      : interOrderType === DROPOFF && interDropOffDetails
      ? interDropOffDetails
      : {};

  return (
    <OutWrapper>
      <Wrapper>
        <CheckIcon className="fal fa-check-circle" />
        <ContentWrapper>
          <Title2 id="SuccessfulOrder-Title">Thank you for your order!</Title2>
          <Body>We emailed your receipt to {userEmail}</Body>
          <div>
            <OrderMethodType id="SuccessfulOrder-OrderMethodType">
              {placedOrder.orderType === DELIVERY
                ? 'Delivering to: '
                : placedOrder.orderType === PICKUP
                ? 'Pick up from: '
                : 'Dine-in: '}
            </OrderMethodType>
            {appConfigFile.isParentChildClient ? (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  {interOrderType === PICKUP
                    ? name
                    : `${deliveryToAddress?.line1}, ${deliveryToAddress?.city}, ${deliveryToAddress?.state}`}
                </div>
                <OrderMethodType>
                  {interOrderType !== DELIVERY
                    ? 'Located in:'
                    : 'Ordering from:'}
                </OrderMethodType>
                {interOrderType !== DELIVERY ? (
                  <>
                    <div>{name}</div>
                    <div>{`${line1}, ${
                      line2 ? `${line2},` : ''
                    }    ${city}, ${state}`}</div>
                  </>
                ) : interDeliveryDetails ? (
                  <>
                    <div>{interDeliveryDetails.name}</div>
                    <div>{`${interDeliveryDetails.line1}, ${
                      interDeliveryDetails.line2
                        ? `${interDeliveryDetails.line2},`
                        : ''
                    }    ${interDeliveryDetails.city}, ${
                      interDeliveryDetails.state
                    }`}</div>
                  </>
                ) : null}
              </div>
            ) : !isAuthenticated ? (
              <>
                {`${line1 ? line1 + ', ' : ''} ${
                  city ? city + ', ' : ''
                } ${state}`}
              </>
            ) : (
              <>
                {placedOrder?.address?.name &&
                  placedOrder?.address?.name + ' - '}
                {getAddress(placedOrder)}
              </>
            )}
          </div>
          {isOrderStatusEnabled &&
            appConfig &&
            (!appConfig.guest_checkout_enabled || isAuthenticated) && (
              <Button
                id="SuccessfulOrder-TrackOrder"
                onClick={() => {
                  if (deepLink) {
                    navigate('/' + deepLink);
                  } else {
                    navigate('/');
                  }
                }}
              >
                Track your order
              </Button>
            )}
        </ContentWrapper>
      </Wrapper>
      <BackToHomeButton
        id="SuccessfulOrder-BackToHome"
        onClick={() => {
          if (deepLink) {
            navigate('/' + deepLink);
          } else {
            navigate('/');
          }
        }}
      >
        &lt; Back to home
      </BackToHomeButton>
    </OutWrapper>
  );
};

export default SuccessfulOrder;
