import React, {Fragment, useState} from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import {PrimaryButtonHeadline, ButtonRow} from '../../../../components/Buttons';
import Title1 from '../../../../components/Typography/Title1';
import {PrimaryButton} from '../../../../components/Buttons';
import FormGrid from '../../../../components/Form/FormGrid';

const Transition = props => <Slide direction="up" {...props} />;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const FormLayout = styled(FormGrid)`
  grid-gap: 20px;
`;

const Button = styled(PrimaryButton)`
  padding: 19px 16px 19px 24px;
  font-size: 19px;
  font-weight: 600;

  &:hover {
    transition: all 0.25s ease;
  }
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
    margin: 0,
  },
  paper: {
    margin: 0,
    width: '100%',
    boxShadow: 'none',
  },
  paper1: {
    margin: 0,
    width: '100%',
  },
});

const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  align-items: center;
  justify-self: center;
  margin: 20px;
`;

let GiftCardContainer = ({
  classes,
  isGiftCardModalOpen,
  setIsGiftCardModalOpen,
  updateGuestGiftCardDetails,
  guestGiftCardDetails,
}) => {
  const handleConnectExistingGiftCard = values => {
    updateGuestGiftCardDetails(values);
    setIsGiftCardModalOpen(false);
  };

  return (
    <Dialog
      id="GiftCard-Dialog"
      onBackdropClick={() => setIsGiftCardModalOpen(false)}
      open={isGiftCardModalOpen}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      TransitionComponent={Transition}
      classes={classes.root}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{
        classes: {root: classes.paper1},
      }}
      aria-labelledby="create-gift-card"
      overlayStyle={{backgroundColor: 'transparent'}}
      scroll="body"
    >
      <Wrapper>
        <TitleWrapper>
          <Title1 style={{fontWeight: '600'}}>Add Gift Card</Title1>
          <Cancel onClick={() => setIsGiftCardModalOpen(false)}>
            <i className="fal fa-times" />
          </Cancel>
        </TitleWrapper>
        <Formik
          initialValues={{
            gift_card_number: guestGiftCardDetails
              ? guestGiftCardDetails.gift_card_number
              : '',
            pin: guestGiftCardDetails ? guestGiftCardDetails.pin : '',
          }}
          validate={values => {
            let errors = {};
            if (!values.gift_card_number) {
              errors.gift_card_number = 'Required';
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleReset,
            dirty,
            isValid,
            setFieldValue,
          }) => (
            <form>
              <FormLayout>
                <NumberFormat
                  id="ExistingGiftCard-CardNumber"
                  label="Gift Card Number (Required)"
                  name="gift_card_number"
                  value={values.gift_card_number}
                  onChange={e =>
                    setFieldValue('gift_card_number', e.target.value)
                  }
                  customInput={TextField}
                  onBlur={handleBlur}
                  error={
                    touched.gift_card_number && errors.gift_card_number
                      ? true
                      : false
                  }
                  helperText={
                    touched.gift_card_number && errors.gift_card_number
                      ? 'Please enter gift card number'
                      : ''
                  }
                  format="#### #### #### #### #### ###"
                />
                <NumberFormat
                  id="ExistingGiftCard-Pin"
                  label="Pin"
                  name="pin"
                  value={values.pin}
                  onChange={e => setFieldValue('pin', e.target.value)}
                  customInput={TextField}
                  onBlur={handleBlur}
                  error={touched.pin && errors.pin ? true : false}
                  helperText={touched.pin && errors.pin}
                />

                <ButtonRow justifyContent="center">
                  <PrimaryButtonHeadline
                    id="ExistingGiftCard-AddGiftCard"
                    type="submit"
                    disabled={!dirty || !isValid}
                    onClick={() => {
                      handleReset();
                      handleConnectExistingGiftCard(values);
                    }}
                  >
                    Add Gift Card
                  </PrimaryButtonHeadline>
                </ButtonRow>
              </FormLayout>
            </form>
          )}
        </Formik>
      </Wrapper>
    </Dialog>
  );
};

export default withStyles(styles)(GiftCardContainer);
