import React from 'react';
import CartContainer from '../modules/Cart/CartContainer';
import GiftCardContainer from '../modules/GiftCard/giftCardContainer';
import appConfig from '../../app-config';

const CartPage = props => {
  const isFoodRequired = props.location.state
    ? props.location.state.isFoodRequired
    : null;
  return (
    <div>
      {<CartContainer {...props} />}
      {appConfig.isGiftCardEnabled && <GiftCardContainer />}
    </div>
  );
};

export default CartPage;
