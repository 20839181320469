import React, { Fragment, useState, useEffect, useRef, Suspense } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import * as actions from '../../../PaymentMethods/paymentMethodsActions';
import { clearErrorMessage } from '../../cartActions';
import { Caption1, Title3 } from '../../../../components/Typography';
import { PrimaryButton } from '../../../../components/Buttons';
import OrderSettingSummaryContainer from '../../../OrderSetting/OrderSettingSummary/OrderSettingSummaryContainer';
import PriceBreakdown from '../../components/PriceBreakdown';
import AddTipContainer from '../../AddTip/AddTipContainer';
import appConfigFile from '../../../../../app-config';
import CartPaymentMethodContainer from '../../CartPaymentMethod/CartPaymentMethodContainer';
import PaymentType from '../../../PaymentMethods/GuestPaymentDetails/PaymentType';
import PayOnArrival from '../../../PaymentMethods/GuestPaymentDetails/PayOnArrival';
import AddGiftCardModal from './AddGiftCardModal';
import PaymentMethodDetailsForm from '../../../PaymentMethods/GuestPaymentDetails/PaymentMethodDetailsForm';
import validateCreditCard from '../utils/validateCreditCard';
import {
  ASAP,
  PICKUP,
  DROPOFF,
  DELIVERY,
} from '../../../OrderSetting/orderSettingTypes';
import ConnectExistingGiftCard from '../../../GiftCard/components/ConnectExistingGiftCard';
import AddNewPaypalPaymentMethodButton from '../../../PaymentMethods/components/AddNewPaypalPaymentMethodButton';
import spinner from '../../../../assets/spinner.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
const CartWrapper = styled.div`
  display: grid;
  grid-gap: 60px;
  margin: auto;

  @media (max-width: 786px) {
    margin-top: 40px;
  }
`;

const Card = styled.div`
  display: grid;
  grid-gap: 24px;
  width: 900px;

  margin: auto;
  @media (max-width: 786px) {
    width: 100%;
    max-width: 650px;
  }
`;

const Hr = styled.hr`
  background-color: white;
  border: none;
  border-top: solid 0.11em ${(props) => props.theme.guestCheckoutHrColor};
  max-width: 710px;
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  @media (max-width: 786px) {
    width: max-content;
  }

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const PayOnArrivalTitle = styled(Title3)`
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  @media (max-width: 786px) {
    width: max-content;
  }
`;

const TipCardWrapper = styled(Card)`
  grid-gap: 45px;
`;

const PriceBreakdownWrapper = styled.div`
  width: 530px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 786px) {
    width: 100%;
    max-width: 650px;
  }
`;

const PlaceOrderRow = styled.div`
  width: 710px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 480px) {
    width: 100%;
    grid-auto-flow: row;
    justify-content: stretch;
    grid-gap: 28px;
    margin: auto;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 350px;
    grid-auto-flow: row;
    justify-content: stretch;
    grid-gap: 28px;
    margin: auto;
  }
`;

const BackButton = styled(Caption1)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02px;

  :hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }

  @media (max-width: 786px) {
    grid-row: 2;
    text-align: center;
  }
`;

const CheckoutButton = styled(PrimaryButton)`
  @media (max-width: 786px) {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 560px;
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 21px;
    grid-template-rows: auto auto;
  }
`;

const PaymentMethod = styled.div`
  padding-left: 80px;
  @media (max-width: 786px) {
    padding-left: 0;
  }
`;

const AddGiftCardButtonWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  align-items: center;
  justify-self: center;
  cursor: pointer;
  border: solid 2px #dbdbdb;
  padding: 19px 16px 19px 24px;
  border-radius: 6px;
  width: 100%;

  :hover {
    border-color: ${(props) => props.theme.addCreditCardBorderColorHover};
    color: ${(props) => props.theme.addCreditCardBorderColorHover};
    transition: all 0.25s ease;
  }
`;

const GiftCardWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 40px auto;
  align-items: center;
  justify-self: center;
  border: solid 2px #f3f3f3;
  padding: 19px 16px 19px 24px;
  border-radius: 6px;
  width: 100%;
  background-color: #f3f3f3;
`;

const ChangeButton = styled.div`
  color: ${(props) => props.theme.primaryButtonFontColor};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
`;
const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;
const LoaderWrapper = styled.div`
  width: 350px;
  height: 350px;
  position: absolute;
  top: 50%;
  z-index: 1001;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;
const LoaderMessage = styled.div`
  color: white;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 19px;
  margin-top: 20px;
  text-align: center;
`;

const PaymentDetailsForm = ({
  validatedCart,
  clearErrorMessage,
  changeGuestPaymentMethod,
  guestPaymentMethod,
  orderSettingReducer,
  locationsReducer,
  handlePlaceGuestOrder,
  authReducer,
  connectExistingGiftCard,
  paymentMethodsReducer,
  updateGuestPaymentDetails,
  updateGuestGiftCardDetails,
  setTouchedCreditCardDetails,
  tipPercentage,
  resetTipPercentage,
  is_tip_enabled,
  validateGlobalCart,
  isGuestSession,
  cartReducer,
  requestingValidateCart,
  isPayOnArrivalSelected,
  updateIsPayPalButtonDisplayed,
  payPalNonce,
  getClientToken,
  globalErrors,
  resetPayPalNonce,
}) => {
  const deepLink = localStorage.getItem('deep-link-url');
  const payPalButtonRef = useRef(null);
  const [displayLoading, setDisplayLoading] = useState(true);
  const {
    interOrderType,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
  } = orderSettingReducer;

  let {
    guestCreditCardDetails,
    guestGiftCardDetails,
    isTouchedCreditCardDetails,
    isPayPalButtonDisplayed,
  } = paymentMethodsReducer;

  const { requestingPlaceOrder } = cartReducer;

  useEffect(() => {
    changeGuestPaymentMethod('');
    return () => {
      resetTipPercentage();
      clearErrorMessage();
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isGuestSession) {
      timer = setTimeout(() => validateGlobalCart(), 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      const payPalButton = document.getElementById('paypal-button-container');
      if (!!payPalButton?.innerHTML && displayLoading) {
        setDisplayLoading(false);
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);
  const [isGiftCardModalOpen, setIsGiftCardModalOpen] = useState(false);
  const [payOnArrivalPaymentMethod, setPayOnArrivalPaymentMethod] = useState(
    'creditCard'
  );

  const isPostalCodeEnabled = authReducer.appConfig
    ? authReducer.appConfig.validate_avs
    : false;
  const isAddressEnabled =
    authReducer.appConfig &&
    authReducer.appConfig.validate_avs &&
    authReducer.appConfig.validate_avs_street;

  let initialValues = guestCreditCardDetails;

  const verifyIsPlaceOrderDisabled = () => {
    if (guestPaymentMethod === '') return true;
    else if (guestPaymentMethod === 'giftCard' && !guestGiftCardDetails)
      return true;
    else if (requestingPlaceOrder) return true;

    return false;

    // guestPaymentMethod === ""
    //   ? true
    //   : guestPaymentMethod === "creditCard"
    //   ? (Object.keys(errors).length > 0 && touched) ||
    //     isTouchedCreditCardDetails === false
    //   : guestPaymentMethod === "giftCard"
    //   ? !guestGiftCardDetails
    //   : requestingPlaceOrder
    //   ? true
    //   : false;
  };
  if (isPostalCodeEnabled) {
    initialValues = {
      ...initialValues,
      postal_code: initialValues?.postal_code ? initialValues.postal_code : '',
    };
  }
  if (isAddressEnabled) {
    initialValues = {
      ...initialValues,
      street_number: initialValues?.street_number
        ? initialValues.street_number
        : '',
      street_name: initialValues?.street_name ? initialValues.street_name : '',
    };
  }

  const isAddTipHidden =
    appConfigFile.appSource === 'mary_browns' && interOrderType === PICKUP
      ? true
      : false;
  useEffect(() => {
    clearErrorMessage();
    if (guestPaymentMethod === 'paypal' && !globalErrors?.message) {
      window.scrollTo(0, payPalButtonRef.current.offsetTop);
    }
    if (guestPaymentMethod !== 'paypal') {
      resetPayPalNonce();
    }
  }, [guestPaymentMethod]);
  return (
    <div>
      {guestPaymentMethod === 'paypal' &&
        payPalNonce &&
        !globalErrors?.message && (
          <BackDrop>
            <LoaderWrapper>
              <CircularProgress
                size={70}
                style={{ color: 'white', display: 'block', margin: 'auto' }}
              />
              <LoaderMessage>
                Processing your payment and <br /> placing your order...
              </LoaderMessage>
            </LoaderWrapper>
          </BackDrop>
        )}
      <Formik
        initialValues={initialValues}
        validate={(values) =>
          validateCreditCard(
            values,
            guestPaymentMethod,
            isPostalCodeEnabled,
            isAddressEnabled
          )
        }
        onSubmit={(values) => {
          handlePlaceGuestOrder(
            values,
            guestGiftCardDetails,
            guestPaymentMethod
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form>
              <CartWrapper>
                <Card>
                  <OrderSettingSummaryContainer
                    notEditable={true}
                    isForReviewPage
                  />
                </Card>
                <Card>
                  <PaymentType
                    guestPaymentMethod={guestPaymentMethod}
                    changeGuestPaymentMethod={changeGuestPaymentMethod}
                    interOrderType={interOrderType}
                    interPickUpDetails={interPickUpDetails}
                    interDeliveryDetails={interDeliveryDetails}
                    interDropOffDetails={interDropOffDetails}
                    authAppConfig={authReducer.appConfig}
                    getClientToken={getClientToken}
                  />
                </Card>
                {guestPaymentMethod !== '' && guestPaymentMethod !== 'poa' && (
                  <Card>
                    <Wrapper>
                      {guestPaymentMethod !== 'paypal' && (
                        <Title3Wrapper id="PaymentMethods-FormTitle">
                          Payment Details
                        </Title3Wrapper>
                      )}
                      {guestPaymentMethod === 'creditCard' ? (
                        <PaymentMethodDetailsForm
                          values={values}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          isAddressEnabled={isAddressEnabled}
                          isPostalCodeEnabled={isPostalCodeEnabled}
                          updateGuestPaymentDetails={updateGuestPaymentDetails}
                          setTouchedCreditCardDetails={
                            setTouchedCreditCardDetails
                          }
                        />
                      ) : guestPaymentMethod === 'giftCard' ? (
                        <>
                          {!guestGiftCardDetails?.gift_card_number ? (
                            <PaymentMethod>
                              <AddGiftCardButtonWrapper
                                id="AddGiftCardButton"
                                onClick={() => setIsGiftCardModalOpen(true)}
                              >
                                <div>Add Gift Card</div>
                                <div style={{ textAlign: 'right' }}>
                                  <i
                                    class="fas fa-gift-card"
                                    style={{ fontSize: '29px' }}
                                  />
                                </div>
                              </AddGiftCardButtonWrapper>
                            </PaymentMethod>
                          ) : (
                            <PaymentMethod>
                              <GiftCardWrapper id="GiftCard">
                                <i
                                  className="fas fa-gift-card"
                                  style={{ fontSize: '29px' }}
                                />
                                <div>Gift Card</div>
                              </GiftCardWrapper>
                              <ChangeButton
                                onClick={() => setIsGiftCardModalOpen(true)}
                              >
                                Change
                              </ChangeButton>
                            </PaymentMethod>
                          )}
                        </>
                      ) : // : guestPaymentMethod === 'paypal' ? (
                      //   <PaymentMethod>
                      //     <AddNewPaypalPaymentMethodButton
                      //       isPayPalButtonDisplayed={isPayPalButtonDisplayed}
                      //       updateIsPayPalButtonDisplayed={
                      //         updateIsPayPalButtonDisplayed
                      //       }
                      //       isForGuestOrdering={true}
                      //     />
                      //   </PaymentMethod>
                      // )
                      null}
                    </Wrapper>
                  </Card>
                )}
                <Card>
                  {!appConfigFile.isNoTipEnabled && !isPayOnArrivalSelected && (
                    <TipCardWrapper>
                      <AddTipContainer
                        guestCheckoutPage={true}
                        tipPercentage={tipPercentage}
                      />
                    </TipCardWrapper>
                  )}
                  <Hr />
                </Card>
                {validatedCart && (
                  <Fragment>
                    <PriceBreakdownWrapper>
                      <PriceBreakdown
                        validatedCart={validatedCart}
                        orderSettingReducer={orderSettingReducer}
                        locationsReducer={locationsReducer}
                        is_tip_enabled={is_tip_enabled}
                        isAddTipHidden={isAddTipHidden}
                        isPayOnArrivalSelected={isPayOnArrivalSelected}
                      />
                    </PriceBreakdownWrapper>
                    <Hr />
                  </Fragment>
                )}
                <PlaceOrderRow>
                  <Fragment>
                    <BackButton
                      id="PaymentDetails-BackToMenu"
                      onClick={() => {
                        if (deepLink) {
                          navigate('/' + deepLink);
                        } else {
                          navigate('/');
                        }
                      }}
                    >
                      <i
                        className="fal fa-chevron-left"
                        style={{ fontWeight: '600' }}
                      ></i>{' '}
                      Back To Menu
                    </BackButton>
                    {guestPaymentMethod === 'paypal' &&
                    !globalErrors?.message ? (
                      <div
                        style={{
                          minWidth: '280px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        ref={payPalButtonRef}
                      >
                        <div
                          id="paypal-button-container"
                          style={{
                            minWidth: '280px',
                            zIndex: 1,
                          }}
                        ></div>
                        {displayLoading && (
                          <img
                            src={spinner}
                            alt="loader"
                            width={80}
                            height={80}
                          />
                        )}
                      </div>
                    ) : (
                      <CheckoutButton
                        id="PaymentDetails-PlaceOrder"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={
                          guestPaymentMethod === '' ||
                          (globalErrors &&
                            Object.keys(globalErrors).length > 0) ||
                          (guestPaymentMethod === 'paypal' && !payPalNonce) ||
                          (guestPaymentMethod === 'giftCard' &&
                            !guestGiftCardDetails) ||
                          (guestPaymentMethod === 'creditCard' &&
                            Object.keys(errors).length > 0) ||
                          (guestPaymentMethod === 'poa' &&
                            !isPayOnArrivalSelected) ||
                          requestingPlaceOrder ||
                          requestingValidateCart
                        }
                      >
                        Place Order
                      </CheckoutButton>
                    )}
                  </Fragment>
                </PlaceOrderRow>
                <AddGiftCardModal
                  isGiftCardModalOpen={isGiftCardModalOpen}
                  setIsGiftCardModalOpen={setIsGiftCardModalOpen}
                  connectExistingGiftCard={connectExistingGiftCard}
                  updateGuestGiftCardDetails={updateGuestGiftCardDetails}
                  guestGiftCardDetails={guestGiftCardDetails}
                />
              </CartWrapper>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    guestPaymentMethod: state.paymentMethodsReducer.guestPaymentMethod,
    isPayOnArrivalSelected: state.paymentMethodsReducer.isPayOnArrivalSelected,
    payPalNonce: state.paymentMethodsReducer.payPalNonce,
    authAppConfig: state.authReducer.appConfig,
    globalErrors: state.cartReducer.errors,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  clearErrorMessage,
})(PaymentDetailsForm);
