import React from 'react';
import styled from 'styled-components';
import calculateDiscount from '../utils/calculateDiscout';
import { Body, Headline } from '../../../components/Typography';
import appConfig from '../../../../app-config';
import numeral from 'numeral';
import { DELIVERY, DROPOFF } from '../../OrderSetting/orderSettingTypes';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import { PICKUP } from '../../OrderSetting/orderSettingTypes';

const Wrapper = styled.div`
  width: 240px;
  display: grid;
  grid-gap: 16px;
`;

const LineItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LineItem = styled(Body)`
  text-transform: uppercase;
`;

const Total = styled(Headline)`
  text-transform: uppercase;
`;

const Accordion = withStyles({
  root: {
    border: 0,
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      border: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const Review = ({
  validatedCart,
  orderSettingReducer,
  locationsReducer,
  is_tip_enabled,
  isAddTipHidden,
  isPayOnArrivalSelected,
}) => {
  const {
    orderType,
    interPickUpDetails,
    interDropOffDetails,
    interDeliveryDetails,
  } = orderSettingReducer;

  const { locations } = locationsReducer;
  const { service_fees } = validatedCart;

  const currentLocation =
    orderType === 'pickup' && interPickUpDetails
      ? locations.filter((location) => location?.id === interPickUpDetails?.id)
      : orderType === 'drop_off'
      ? locations.filter((location) => location?.id === interDropOffDetails?.id)
      : locations.filter(
          (location) =>
            location?.id === interDeliveryDetails?.deliveryBusiness?.id
        );

  const currency = currentLocation?.length ? currentLocation[0].currency : null;
  const { interOrderType } = orderSettingReducer;

  const isTipHidden =
    appConfig.appSource === 'mary_browns' && interOrderType === PICKUP
      ? true
      : false;

  return (
    <Wrapper>
      <LineItemRow>
        <LineItem id="PriceBreakDown-Subtotal">Subtotal</LineItem>
        <LineItem id="PriceBreakDown-SubtotalValue">
          {numeral(validatedCart?.subtotal).format('$0.00')}
        </LineItem>
      </LineItemRow>
      <LineItemRow>
        <LineItem id="PriceBreakDown-Discount">Discount</LineItem>
        <LineItem>
          {numeral(calculateDiscount(validatedCart?.credit_breakdown)).format(
            '$0.00'
          )}
        </LineItem>
      </LineItemRow>
      {is_tip_enabled && !isAddTipHidden && !isPayOnArrivalSelected && (
        <LineItemRow>
          <LineItem id="PriceBreakDown-Tip">Tip</LineItem>
          <LineItem id="PriceBreakDown-TipValue">
            {isPayOnArrivalSelected
              ? numeral('$0.00').format('$0.00')
              : numeral(validatedCart.tip).format('$0.00')}
          </LineItem>
        </LineItemRow>
      )}

      {service_fees && service_fees.length > 0
        ? service_fees.map((serviceFee, i) => (
            <LineItemRow key={i}>
              <LineItem id={`PriceBreakDown-${serviceFee.display_name}`}>
                {serviceFee.display_name}
              </LineItem>
              <LineItem id={`PriceBreakDown-${serviceFee.display_name}-Amount`}>
                {numeral(serviceFee.amount).format('$0.00')}
              </LineItem>
            </LineItemRow>
          ))
        : null}

      {/* {service_fees.length > 0 && (
        <Accordion
          elevation={0}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<i className="fas fa-angle-down" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{
              margin: 0,
              padding: 0,
              boxShadow: 'none',
            }}
          >
            <LineItem>Fees</LineItem>
          </AccordionSummary>
          <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
            {service_fees.map(serviceFee =>
              Object.keys(serviceFee).map(key => (
                <div>
                  <LineItemRow>
                    <LineItem id={key}>{key.replace('_', ' ')}</LineItem>
                    <LineItem id={`${serviceFee[key]}-value`}>
                      {numeral(serviceFee[key]).format('$0.00')}
                    </LineItem>
                  </LineItemRow>
                </div>
              ))
            )}
          </AccordionDetails>
        </Accordion>
      )} */}

      {/* {orderSettingReducer.orderType === DELIVERY && (
        <LineItemRow>
          <LineItem id="PriceBreakDown-Delivery">Delivery Fee</LineItem>
          <LineItem id="PriceBreakDown-DeliveryValue">
            {numeral(validatedCart.fees).format('$0.00')}
          </LineItem>
        </LineItemRow>
      )} */}
      <LineItemRow>
        <LineItem id="PriceBreakDown-Tax">Tax</LineItem>
        <LineItem id="PriceBreakDown-TaxValue">
          {numeral(validatedCart.tax).format('$0.00')}
        </LineItem>
      </LineItemRow>
      <LineItemRow style={{ marginTop: '4px' }}>
        <Total id="PriceBreakDown-Total">Total</Total>
        <Total id="PriceBreakDown-TotalValue">
          {numeral(validatedCart.total).format('$0.00')}
        </Total>
      </LineItemRow>
      <div style={{ textAlign: 'right' }}>
        {currency
          ? `All prices are in 
        ${
          orderSettingReducer.orderType === DELIVERY
            ? orderSettingReducer.deliveryDetails.deliveryBusiness.currency
            : orderSettingReducer.orderType === DROPOFF
            ? orderSettingReducer.dropOffDetails.currency
            : orderSettingReducer.interPickUpDetails.currency
        }`
          : null}
      </div>
    </Wrapper>
  );
};

export default Review;
