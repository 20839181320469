import React from 'react';
import styled from 'styled-components';
import {Title3} from '../../../components/Typography';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  helperText: {
    marginRight: 0,
    textAlign: 'end',
  },
});

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${props => (props.isForReviewPage ? '0' : '38px')};
  grid-template-columns: ${props =>
    props.isForReviewPage ? '150px 1fr' : '1fr'};

  @media (max-width: 768px) {
    grid-gap: 24px;
    grid-template-columns: 1fr;
  }
`;

const TextArea = styled(TextField)`
  margin-left: 80px;
  @media (max-width: 786px) {
    margin-left: 0;
  }
  font-family: ${props => props.theme.primaryFontFontFamily};
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${props => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const SpecialInstructions = ({
  classes,
  specialInstructions,
  changeSpecialInstructions,
  isForReviewPage,
}) => {
  const specialInstructionsLimit = 180;

  return (
    <Wrapper isForReviewPage={isForReviewPage}>
      <Title3Wrapper id="SpecialInstructions-Title">
        Order Instructions
      </Title3Wrapper>
      <TextArea
        id="SpecialInstructions-Notes"
        value={specialInstructions}
        onChange={event => changeSpecialInstructions(event.target.value)}
        multiline
        rows="3"
        variant="outlined"
        placeholder="E.g. Please add utensils"
        type="text"
        helperText={`${specialInstructions.length}/${specialInstructionsLimit}`}
        inputProps={{maxLength: 180}}
        FormHelperTextProps={{classes: {root: classes.helperText}}}
      />
    </Wrapper>
  );
};

export default withStyles(styles)(SpecialInstructions);
