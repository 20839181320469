import React, {useState, useEffect} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import styled from 'styled-components';
import OfferCard from '../../Offers/OfferDetails/components/OfferCard';
import OfferDetailsContainer from '../../Offers/OfferDetails/OfferDetailsContainer';
import InternalLoyaltyCard from '../../Loyalty/InternalLoyaltyCard/InternalLoyaltyCardContainer';

const MainDiv = styled.div`
  *:focus {
    outline: 0;
    outline: none;
  }
  margin-top: 30px;
  margin-bottom: 50px;
`;

const SliderWrapper = styled.div``;

const NextArrow = styled.div`
  @media (max-width: 786px) {
    display: none !important;
  }
`;

const PrevArrow = styled.div`
  @media (max-width: 786px) {
    display: none !important;
  }
`;

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <NextArrow
      id="CartActivatedOffers-NextArrow"
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#fff',
        borderRadius: '50%',
        border: 'none',
        height: '40px',
        width: '40px',
        boxShadow: ' 0 2px 5px 0 rgba(59, 76, 112, 0.24)',
        right: '-29px',
        opacity: 1,
        lineHeight: '40px',
        textAlign: 'center',
        //top: '102px',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <PrevArrow
      id="CartActivatedOffers-PrevArrow"
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#fff',
        borderRadius: '50%',
        border: 'none',
        height: '40px',
        width: '40px',
        boxShadow: ' 0 2px 5px 0 rgba(59, 76, 112, 0.24)',
        right: '-20px',
        opacity: 1,
        lineHeight: '40px',
        textAlign: 'center',
        zIndex: '1',
        //top: '102px',
      }}
      onClick={onClick}
    />
  );
}

const CartActivatedOffers = ({offers, onClick, isFromReviewPage}) => {
  var settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    variableWidth: true,
    // slidesPerRow: 1,
    // rows: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          className: 'slider variable-width',
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 500,
          arrows: true,
          swipeToSlide: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          className: 'slider variable-width',
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 500,
          arrows: true,
          swipeToSlide: true,
          variableWidth: true,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          className: 'slider variable-width',
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 500,
          arrows: true,
          swipeToSlide: true,
          variableWidth: true,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 500,
          arrows: false,
          swipeToSlide: true,
          variableWidth: true,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 500,
          arrows: false,
          swipeToSlide: true,
          variableWidth: true,
          // nextArrow: false,
          // prevArrow: false,
        },
      },
    ],
  };

  return (
    <MainDiv>
      <div className="slider-wrapper">
        <Slider {...settings}>
          {/* <div key={0} style={{width: '470px'}}>
            <InternalLoyaltyCard />
          </div> */}
          {offers.map((offer, index) => {
            if (offer.activated || offer.eligible)
              return (
                <div key={index}>
                  <OfferDetailsContainer
                    offer={offer}
                    onClick={onClick}
                    noMarginTop
                    carouselCard
                    isFromReviewPage={isFromReviewPage}
                  />
                </div>
              );
          })}
        </Slider>
      </div>
    </MainDiv>
  );
};

export default CartActivatedOffers;
