import {GET_UPSELL_REQUEST, GET_UPSELL_SUCCESS} from './UpsellTypes';
import api from '../../api';

export function getUpsell(businessId) {
  return function(dispatch) {
    dispatch({type: GET_UPSELL_REQUEST});
    api
      .get(`/product-upsell?business_id=${businessId}`)
      .then(response => {
        dispatch({
          type: GET_UPSELL_SUCCESS,
          upsellProducts: response.data.data,
        });
      })
      .catch(error => console.error(error));
  };
}
