import React from 'react';
import {connect} from 'react-redux';
import {editProductConfiguration} from '../../../ProductCustomizer/productCustomizerActions';
import {
  removeProductFromCart,
  decrementProduct,
  incrementProduct,
  toggleProductIsFavourited,
} from '../../../Cart/cartActions';

import ProductEditAndQuantityCart from './ProductEditAndQuantityCart';

const ProductEditAndQuantityCartContainer = ({
  productInCart,
  removeProductFromCart,
  decrementProduct,
  incrementProduct,
  editProductConfiguration,
  toggleProductIsFavourited,
  isForPreview,
  isAuthenticated,
}) => {
  const {productIndexInCart, flatProduct, favouriteId} = productInCart;

  const handleEditProductConfiguration = () =>
    editProductConfiguration(productInCart);

  const handleDecrementProduct = () => {
    if (flatProduct.quantity === 1) {
      removeProductFromCart(productIndexInCart);
    } else {
      decrementProduct(productIndexInCart);
    }
  };

  const handleIncrementProduct = () => {
    incrementProduct(productIndexInCart);
  };

  return (
    <ProductEditAndQuantityCart
      isAuthenticated={isAuthenticated}
      isForPreview={isForPreview}
      quantity={flatProduct.quantity}
      flatProduct={flatProduct}
      favouriteId={favouriteId}
      handleDecrementProduct={handleDecrementProduct}
      handleIncrementProduct={handleIncrementProduct}
      handleEditProductConfiguration={handleEditProductConfiguration}
    />
  );
};

const mapStateToProps = state => ({
  isForPreview: state.cartReducer.isForPreview,
  isAuthenticated: state.authReducer.isAuthenticated,
});

const actions = {
  editProductConfiguration,
  removeProductFromCart,
  decrementProduct,
  incrementProduct,
  toggleProductIsFavourited,
};

export default connect(
  mapStateToProps,
  actions
)(ProductEditAndQuantityCartContainer);
