import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

import appConfig from '../../../../app-config';

const PaymentMethod = styled.div`
  padding-left: 80px;
  @media (max-width: 786px) {
    padding-left: 0;
  }
`;

const SupportedCardTypeDiv = styled.div`
  display: flex;
  gap: 10px;
`;

const SupportedCardTypeIcon = styled.img`
  width: 40px;
  height: 22px;
  object-fit: ${(props) => (props.cardType == 'amex' ? 'cover' : 'contain')};
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template: repeat(3, 62px) / 2fr 1fr;
  grid-gap: 16px 44px;
`;

const PaymentMethodDetailsForm = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  handleChange,
  isAddressEnabled,
  isPostalCodeEnabled,
  updateGuestPaymentDetails,
  setTouchedCreditCardDetails,
}) => {
  const supportedCardType = ['master', 'visa', 'discover', 'amex'];

  const renderCardIconClassName = (type) => {
    switch (type) {
      case 'master':
      case 'mastercard':
        return 'https://s3.amazonaws.com/assets.smoothpay.com/web/general-web-ordering-assets/mc-symbol-opt-45-3-x%403x.png';
      case 'visa':
        return 'https://s3.amazonaws.com/assets.smoothpay.com/web/general-web-ordering-assets/Visa-POS-Graphic_visa_pos_fc.png';
      case 'discover':
        return 'https://s3.amazonaws.com/assets.smoothpay.com/web/general-web-ordering-assets/discover_logo.jpg';
      case 'amex':
        return 'https://s3.amazonaws.com/assets.smoothpay.com/web/general-web-ordering-assets/AXP_BlueBoxLogo_EXTRALARGEscale_RGB_DIGITAL_1600x1600.png';
    }
  };

  useEffect(() => {
    updateGuestPaymentDetails(values);
  }, [values]);

  useEffect(() => {
    if (Object.keys(touched).length !== 0) {
      setTouchedCreditCardDetails(true);
    }
  }, [touched]);

  return (
    <Fragment>
      <PaymentMethod>
        <SupportedCardTypeDiv>
          {supportedCardType.map((cardType) => {
            return (
              <SupportedCardTypeIcon
                cardType={cardType}
                src={renderCardIconClassName(cardType)}
                alt={cardType}
              />
            );
          })}
        </SupportedCardTypeDiv>
        <FieldsWrapper>
          <NumberFormat
            id="PaymentMethods-CardNumber"
            label="Card Number"
            name="card_number"
            // type="number"
            autoComplete="cc-number"
            value={values.card_number}
            onValueChange={(values) =>
              setFieldValue('card_number', values.value)
            }
            customInput={TextField}
            required
            onBlur={handleBlur}
            error={touched.card_number && errors.card_number ? true : false}
            helperText={touched.card_number && errors.card_number}
            format="#### #### #### #### #### ###"
            style={{
              gridColumn: '1 / -1',
              fontFamily: appConfig.styleConfig.primaryFontFontFamily,
            }}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr',
              gridGap: '8px',
            }}
          >
            <NumberFormat
              id="PaymentMethods-ExpiryMonth"
              label="MM"
              name="expiry_month"
              // type="number"
              autoComplete="cc-exp-month"
              value={values.expiry_month}
              onValueChange={(values) =>
                setFieldValue('expiry_month', values.value)
              }
              customInput={TextField}
              required
              onBlur={handleBlur}
              error={touched.expiry_month && errors.expiry_month ? true : false}
              helperText={touched.expiry_month && errors.expiry_month}
              format="##"
              placeholder="MM"
              // mask={['M', 'M']}
              style={{
                fontFamily: appConfig.styleConfig.primaryFontFontFamily,
              }}
            />
            <div
              style={{
                paddingTop: '23px',
                color: 'rgba(0, 0, 0, 0.54)',
              }}
            >
              /
            </div>
            <NumberFormat
              id="PaymentMethods-ExpiryYear"
              label="YYYY"
              name="expiry_year"
              // type="number"
              autoComplete="cc-exp-year"
              value={values.expiry_year}
              onValueChange={(values) =>
                setFieldValue('expiry_year', values.value)
              }
              customInput={TextField}
              required
              onBlur={handleBlur}
              error={touched.expiry_year && errors.expiry_year ? true : false}
              helperText={touched.expiry_year && errors.expiry_year}
              format="####"
              placeholder="YYYY"
              // mask={['Y', 'Y', 'Y', 'Y']}
              style={{
                fontFamily: appConfig.styleConfig.primaryFontFontFamily,
              }}
            />
          </div>
          <NumberFormat
            id="PaymentMethods-CardCVV"
            label="CVV"
            name="cvv"
            autoComplete="cc-csc"
            value={values.cvv}
            onValueChange={(values) => setFieldValue('cvv', values.value)}
            customInput={TextField}
            required
            onBlur={handleBlur}
            error={touched.cvv && errors.cvv ? true : false}
            helperText={touched.cvv && errors.cvv}
            format="####"
            style={{
              fontFamily: appConfig.styleConfig.primaryFontFontFamily,
            }}
          />
          <TextField
            id="PaymentMethods-CardHolderName"
            label="Cardholder Name"
            name="cardholder_name"
            type="text"
            autoComplete="cc-name"
            value={values.cardholder_name}
            onChange={handleChange}
            required
            onBlur={handleBlur}
            error={
              touched.cardholder_name && errors.cardholder_name ? true : false
            }
            helperText={touched.cardholder_name && errors.cardholder_name}
            style={{
              gridColumn: '1 / -1',
              fontFamily: appConfig.styleConfig.primaryFontFontFamily,
            }}
          />
          {
            // for testing only, postal code needs to be V8T 4M3
          }
          {isPostalCodeEnabled ? (
            <TextField
              id="PaymentMethods-PostalCode"
              label="Postal Code"
              name="postal_code"
              type="text"
              // autoComplete="cc-name"
              value={values.postal_code}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
              }}
              required
              onBlur={handleBlur}
              error={touched.postal_code && errors.postal_code ? true : false}
              helperText={touched.postal_code && errors.postal_code}
              style={{
                gridColumn: '1 / -1',
                fontFamily: appConfig.styleConfig.primaryFontFontFamily,
              }}
            />
          ) : null}
          {isAddressEnabled ? (
            <Fragment>
              <NumberFormat
                id="PaymentMethods-StreetNumber"
                label="Street Number"
                name="street_number"
                type="text"
                // autoComplete="cc-name"
                value={values.street_number}
                onChange={handleChange}
                customInput={TextField}
                required
                onBlur={handleBlur}
                error={
                  touched.street_number && errors.street_number ? true : false
                }
                helperText={touched.street_number && errors.street_number}
                style={{
                  gridColumn: '1 / -1',
                  fontFamily: appConfig.styleConfig.primaryFontFontFamily,
                }}
              />
              <TextField
                id="PaymentMethods-StreetName"
                label="Street Name"
                name="street_name"
                type="text"
                // autoComplete="cc-name"
                value={values.street_name}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                error={touched.street_name && errors.street_name ? true : false}
                helperText={touched.street_name && errors.street_name}
                style={{
                  gridColumn: '1 / -1',
                  fontFamily: appConfig.styleConfig.primaryFontFontFamily,
                }}
              />
            </Fragment>
          ) : null}
        </FieldsWrapper>
        {appConfig.isPreAuthMessageEnabled ? (
          <div id="PaymentMethod-PreAuthMessage">
            On submission of your credit card information, we will be performing
            validation for fraud prevention measures. You may see a temporary
            $0.50 hold on your statement. This is not a charge and will be
            removed in 3-5 days.
          </div>
        ) : null}
      </PaymentMethod>
    </Fragment>
  );
};

export default PaymentMethodDetailsForm;
