import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../Combo/comboActions';
import ComboSummaryCart from './ComboSummaryCart';
import {removeComboFromCart} from '../../Cart/cartActions';

const ComboSummaryCartContainer = ({
  combo,
  comboIndex,
  editComboInCart,
  removeComboFromCart,
  validatedCart,
  cartScreen,
}) => {
  const editCombo = () => {
    editComboInCart(combo, comboIndex);
  };

  const removeCombo = () => {
    removeComboFromCart(comboIndex);
  };

  return (
    <ComboSummaryCart
      combo={combo}
      editCombo={editCombo}
      removeCombo={removeCombo}
      validatedCart={validatedCart}
      cartScreen={cartScreen}
    />
  );
};

const mapStateToProps = state => ({
  validatedCart: state.cartReducer.validatedCart,
});

export default connect(mapStateToProps, {
  ...actions,
  removeComboFromCart,
})(ComboSummaryCartContainer);
