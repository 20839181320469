import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Title3 } from "../../../components/Typography";
import appConfig from "../../../../app-config";
import { PICKUP } from "../../OrderSetting/orderSettingTypes";
import { useDispatch, useSelector } from "react-redux";
import cartReducer from "../cartReducer";
import { validateGlobalCart } from "../cartActions";

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${(props) =>
    props.isForCheckoutPage ? "0" : props.guestCheckoutPage ? "0" : "38px"};
  grid-template-columns: ${(props) =>
    props.isForCheckoutPage
      ? "150px 1fr"
      : props.guestCheckoutPage
      ? "150px 560px"
      : "1fr"};
  margin-top: 15px;

  @media (max-width: 768px) {
    grid-gap: 24px;
    grid-template-columns: 1fr;
  }
`;

const TipWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  justify-items: center;
  grid-gap: 11px;
  padding-left: 80px;

  @media (max-width: 786px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    padding-left: 0;
    grid-gap: 5px;
  }
`;

const TipBox = styled.div`
  cursor: pointer;
  height: 48px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: ${(props) =>
    props.isSelected
      ? props.theme.tipBoxSelectedTextColour
      : props.theme.tipBoxTextColour};
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.tipSelectedBackgroundColor
      : props.theme.tipBackgroundColor};
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  :hover {
    background-color: ${(props) =>
      props.isSelected
        ? props.theme.tipBoxSelectedColorHover
        : props.theme.tipBoxColorHover};
    color: ${(props) =>
      props.isSelected && props.theme.tipBoxSelectedTextHover};
    transition: all 0.25s ease;
  }

  @media (max-width: 786px) {
    width: 60px;
  }
`;

const CustomTipInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 0 16px;
  border-radius: ${(props) => props.theme.customPercentageBoxBorderRadius};
  border: 1px solid ${(props) => props.theme.customPercentageBoxBorderColor};

  &:hover {
    border: 1px solid
      ${(props) => props.theme.customPercentageBoxBorderColorHover};
    transition: all 0.25s ease;
  }

  input[type="number"] {
    &::-webkit-inner-spin-button {
      display: none;
    }

    -moz-appearance: textfield;
    font-family: ${(props) => props.theme.primaryFontFontFamily};
  }

  @media (max-width: 786px) {
    padding: 0 6px;
  }
`;

const CustomTipInput = styled.input`
  width: 55px;
  border: none;
  background: inherit;
  text-align: right;

  &:focus {
    outline: none;
  }

  @media (max-width: 786px) {
    width: 55px;
  }
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
    /* // border-bottom: ${(props) =>
      props.categoriesBarSelectedBorderColorWidth} solid
  //   ${(props) => props.theme.primaryColor}; */
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  :after {
    content: "";
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) =>
      props.categoriesBarSelectedBorderColorWidth} solid ${(props) =>
  props.theme.categoriesBarSelectedBorderColor};
  }
`;

const AddTip = ({
  tipPercentage,
  changeTipPercentage,
  isForCheckoutPage,
  guestCheckoutPage,
}) => {
  const dispatch = useDispatch();
  const previousTip = useSelector((state) => state.cartReducer.cart.tip);

  const [customTip, setCustomTip] = useState({
    isBeingUsed: false,
    value: "",
  });

  let tipPercentageOptions;
  switch (appConfig.appSource) {
    case "Sorrentinos":
      tipPercentageOptions = [10, 12, 15];
      break;
    case "shinytea":
      tipPercentageOptions = [5, 8, 10];
      break;
    default:
      tipPercentageOptions = [10, 15, 20, 25];
  }

  useEffect(() => {
    const delayDebounce = setTimeout(() => { 
      if (customTip.isBeingUsed && customTip.value !== "") {
        dispatch(changeTipPercentage(customTip.value));
      } else {
        if (customTip.isBeingUsed && customTip.value === "") {
          dispatch(changeTipPercentage(0));
          // dispatch(validateGlobalCart());
        }
      }
      dispatch(validateGlobalCart());
    }, 500);
    return () => clearTimeout(delayDebounce);
  }, [customTip.isBeingUsed, customTip.value]);

  useEffect(() => {
    if (appConfig.appSource === "feastify") {
      dispatch(changeTipPercentage(10));
      // dispatch(validateGlobalCart());
    }
  }, []);

  const handleAddTip = (e) => {
    if (parseFloat(previousTip) === parseFloat(e)) {
      dispatch(changeTipPercentage(0));
    } else {
      dispatch(changeTipPercentage(parseFloat(e)));
    }
    dispatch(validateGlobalCart());
  };

  return (
    <Wrapper
      isForCheckoutPage={isForCheckoutPage}
      guestCheckoutPage={guestCheckoutPage}
    >
      <Title3Wrapper id="AddTip-Title">Add a Tip</Title3Wrapper>
      <TipWrapper>
        {tipPercentageOptions.map((tipPercentageOption) => (
          <TipBox
            onClick={() => {
              handleAddTip(tipPercentageOption);
            }}
            isSelected={
              tipPercentageOption === parseFloat(customTip.value) ||
              tipPercentageOption === tipPercentage
            }
          >
            {tipPercentageOption}%
          </TipBox>
        ))}
        <CustomTipInputWrapper>
          <CustomTipInput
            value={customTip.value}
            type="number"
            placeholder="Other"
            min="0"
            max="100"
            onChange={(ev) =>
              parseInt(ev.target.value) <= 100
                ? setCustomTip({
                    ...customTip,
                    isBeingUsed: true,
                    value: ev.target.value,
                  })
                : ev.target.value === ""
                ? setCustomTip({ ...customTip, isBeingUsed: true, value: "" })
                : setCustomTip({ ...customTip, value: 100 })
            }
          />
          <div style={{ margin: "auto" }}>%</div>
        </CustomTipInputWrapper>
      </TipWrapper>
    </Wrapper>
  );
};

export default AddTip;
