import React from 'react';
import styled from 'styled-components';
import {Subhead} from './Typography';

const Wrapper = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  padding: 12px;
  align-items: center;
  margin-top: ${props => props.marginTop || 'inherit'};
`;

const InformationWrapper = ({text, marginTop}) => {
  return (
    <Wrapper marginTop={marginTop}>
      <div>
        <i className="fal fa-info-circle" />
      </div>
      <div>
        <Subhead>{text}</Subhead>
      </div>
    </Wrapper>
  );
};

export default InformationWrapper;
