import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import PersonalDetailsForm from './components/PersonalDetailsForm';
import PaymentDetailsForm from './components/PaymentDetailsForm';
import { Title1, Caption1 } from '../../../components/Typography';
import appConfig from '../../../../app-config';
const source = appConfig.appSource;

const GuestCheckoutWrapper = styled.div`
  padding: 0 15px;
  margin-top: 40px;
  width: 100%;
  text-align: center;
`;

const GuestCheckoutContent = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  @media (min-width: 786px) {
    display: block;
    width: 100%;
  }
`;

const PageTitle = styled(Title1)`
  text-transform: ${(props) => props.theme.pageTitleTextTransform};
  font-weight: 600;
  width: max-content;
  margin-bottom: 20px;
`;

const BackButton = styled(Caption1)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02px;

  :hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }
`;

const GuestCheckoutContainer = ({
  setAuthenticationModalIsVisible,
  initiateCheckoutAsGuest,
  validatedCart,
  guestPersonalDetails,
  setGuestPersonalDetails,
  setShowGuestPersonalDetailsPage,
  showGuestPersonalDetailsPage,
  handleValidateGuestCart,
  orderSettingReducer,
  locationsReducer,
  handlePlaceGuestOrder,
  authReducer,
  connectExistingGiftCard,
  paymentMethodsReducer,
  updateGuestPaymentDetails,
  updateGuestGiftCardDetails,
  setTouchedCreditCardDetails,
  cartReducer,
  tipPercentage,
  resetTipPercentage,
  is_tip_enabled,
  validateGlobalCart,
  appConfig,
  requestingValidateCart,
}) => {
  const [screenToShow, setScreenToShow] = useState('personal-details');

  useEffect(() => {
    setAuthenticationModalIsVisible(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screenToShow]);

  return (
    <GuestCheckoutWrapper>
      <GuestCheckoutContent>
        {screenToShow === 'personal-details' ? (
          <Fragment>
            <PageTitle>Guest Checkout</PageTitle>
            <PersonalDetailsForm
              initiateCheckoutAsGuest={initiateCheckoutAsGuest}
              guestPersonalDetails={guestPersonalDetails}
              setGuestPersonalDetails={setGuestPersonalDetails}
              setShowGuestPersonalDetailsPage={setShowGuestPersonalDetailsPage}
              showGuestPersonalDetailsPage={showGuestPersonalDetailsPage}
              authReducer={authReducer}
              cartReducer={cartReducer}
              setScreenToShow={setScreenToShow}
              validateGlobalCart={validateGlobalCart}
            />
          </Fragment>
        ) : (
          <Fragment>
            <BackButton
              id="GuestCheckout-BackToMenu"
              onClick={() => setScreenToShow('personal-details')}
            >
              <i
                className="fal fa-chevron-left"
                style={{ fontWeight: '600' }}
              ></i>{' '}
              Back To Personal Details
            </BackButton>
            <PageTitle>Guest Checkout</PageTitle>
            <PaymentDetailsForm
              validatedCart={validatedCart}
              orderSettingReducer={orderSettingReducer}
              locationsReducer={locationsReducer}
              handlePlaceGuestOrder={handlePlaceGuestOrder}
              authReducer={authReducer}
              connectExistingGiftCard={connectExistingGiftCard}
              paymentMethodsReducer={paymentMethodsReducer}
              updateGuestPaymentDetails={updateGuestPaymentDetails}
              updateGuestGiftCardDetails={updateGuestGiftCardDetails}
              setTouchedCreditCardDetails={setTouchedCreditCardDetails}
              tipPercentage={tipPercentage}
              resetTipPercentage={resetTipPercentage}
              is_tip_enabled={is_tip_enabled}
              validateGlobalCart={validateGlobalCart}
              isGuestSession={appConfig && appConfig.guest_checkout_enabled}
              cartReducer={cartReducer}
              requestingValidateCart={requestingValidateCart}
            />
          </Fragment>
        )}
      </GuestCheckoutContent>
    </GuestCheckoutWrapper>
  );
};

export default GuestCheckoutContainer;
