import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import CartActivatedOffers from './components/CartActivatedOffers';

const CartActivatedOffersContainer = ({
  reviewScreenOffers,
  isRequestingGetOffers,
  isFromReviewPage,
}) => {
  return (
    <div>
      <CartActivatedOffers
        id="CartActivatedOffers"
        offers={reviewScreenOffers}
        isFromReviewPage={isFromReviewPage}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  //offers: state.offersReducer.offers,
  reviewScreenOffers: state.offersReducer.reviewScreenOffers,
  isRequestingGetOffers: state.offersReducer.isRequestingGetOffers,
});

export default connect(mapStateToProps, null)(CartActivatedOffersContainer);
