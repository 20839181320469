import React, {memo} from 'react';
import styled from 'styled-components';
import {Title3, Body} from '../../components/Typography';
import IQFoodEmptyCart from './ClientEmptyCart/IQFoodEmptyCart';
import appConfig from '../../../app-config';

const EmptyCartWrapper = styled.div`
  margin: auto;
  margin-top: 50px;
`;

const EmptyCartIcon = styled.div`
  padding: 14px;
  color: ${props => props.theme.emptyCartColor};
  margin: auto;
  border-radius: 4px;
  border: 2px dashed ${props => props.theme.emptyCartColor};
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${props => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const EmptyWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  margin: 0 52px;
  text-align: center;
  line-height: 1.5;
  color: ${props => props.theme.emptyCartFontColor};
  font-family: ${props => props.theme.primaryFontFontFamily};
`;

const BodyWrapper = styled(Body)`
  text-transform: ${props => props.theme.reviewCheckoutPageTitle};
  font-weight: 600;
`;

const RenderEmptyCart = () => {
  if (appConfig.appSource === 'iQ') {
    return <IQFoodEmptyCart />;
  }
  return (
    <div>
      <EmptyWrapper>
        <EmptyCartWrapper>
          <EmptyCartIcon
            className="fal fa-shopping-cart"
            style={{fontSize: '32px'}}
          />
        </EmptyCartWrapper>
        <div id="EmptyCart-Message">
          <p>Your Cart is Empty</p>
          <p>Add Items to Your Order to Checkout</p>
        </div>
      </EmptyWrapper>
    </div>
  );
};

const EmptyCart = ({isForPreview}) => {
  return (
    <div>
      {!isForPreview ? (
        <Title3Wrapper id="EmptyCart-OrderSummary">Order Summary</Title3Wrapper>
      ) : (
        <BodyWrapper id="EmptyCart-OrderSummary">Order Summary</BodyWrapper>
      )}
      {RenderEmptyCart()}
    </div>
  );
};

export default EmptyCart;
