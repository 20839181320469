import React, {Fragment} from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import ImageFadeIn from 'react-image-fade-in';
import optionsForProductSummary from './utils/optionsForProductSummary';
import renderSelectedItemsString from '../../../utils/renderSelectedItemsString';
import ProductEditAndQuantityCartContainer from './ProductEditAndQuantityCart/ProductEditAndQuantityCartContainer';
import appConfig from '../../../../app-config';
import {Subhead, Caption2, Body} from '../../../components/Typography';
import CalorieString from '../../../components/CalorieString';
import FavouriteIconContainer from '../../Favourites/FavouriteIcon/FavouriteIconContainer';

const Wrapper = styled.div`
  padding: ${props => (props.isForPreview ? '24px 20px' : '24px 20px 24px 0')};
  display: grid;
  grid-template-columns: ${props =>
    props.isForPreview ? '80px 1fr' : '110px 1fr'};
  align-items: flex-start;
  display: grid;
  grid-gap: ${props => (props.isForCombo ? '5px' : '14px 24px')};

  border-bottom: ${props =>
    props.isLastProductInCart
      ? 'none'
      : `1px solid ${props.theme.productSummaryBorderColor}`};
  margin-bottom: ${props => (props.isForPreview ? '40px' : 'unset')};
  @media (max-width: 786px) {
    grid-template-columns: 80px 1fr;
  }
`;

const TitleAndPriceWrapper = styled.div`
  display: grid;
  grid-column: ${props => (props.isNoImage ? 'span 2' : '1fr 1fr 1fr')};
  align-content: stretch;
  align-self: stretch;
`;

const ProductTitle = styled(Subhead)`
  // text-transform: uppercase;
`;

const ProductImage = styled(ImageFadeIn)`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  // border: ${props => props.theme.productPreviewBorder};
`;

const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isForPreview ? '80px 1fr' : '110px 1fr'};
  grid-gap: ${props => (props.isForCombo ? '5px' : '24px')};
  padding: 5px 0;
  @media (max-width: 786px) {
    grid-template-columns: 80px 1fr;
  }
`;

const Label = styled(Caption2)`
  color: ${props => props.theme.productLabelColor};
  text-transform: uppercase;
`;

// const Price = styled(Caption1)`
//   color: ${props => props.theme.darkGray};
//   justify-self: flex-end;
// `;

const Text = styled(Caption2)`
  text-transform: capitalize;
  span {
    font-size: 13px;
    font-weight: 500;
  }
`;
const EditQuantityRow = styled.div`
  grid-column: span 2;
`;

const Price = styled(Body)``;

const CalPriceRow = styled.div`
  display: flex;
  // grid-template-columns: auto  auto ; 
  grid-gap: 5px;
  align-items: center;
  // justify-content: ${props => (props.cals ? 'space-between' : 'flex-end')};
  // align-self: flex-end;
  margin-top: 24px;
  grid-column: ${props => (props.isForPreview ? 'span 2' : 'auto')};
  grid-column-start: ${props => (props.isForPreview ? '0' : '2')};
`;
const NoteWraper = styled.div`
  width: 100%;
  margin: 0 5px 15px 0;
`;
const NoteLabel = styled.span`
  width: 100%;
  height: 17px;
  margin: 0 4px 15px 0;
  font-family: ${appConfig.styleConfig.appFontFamily};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: var(--colors-basic-dark);
`;
const NoteText = styled.span`
  width: 100%;
  white-space: pre-line;
  height: 17px;
  margin: 0 0 0 4px;
  font-family: ${appConfig.styleConfig.appFontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: var(--colors-basic-dark);
`;
const Dot = () => (
  <i style={{fontSize: '3px', textAlign: 'center'}} className="fas fa-circle" />
);
const TitleIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ProductSummaryCart = ({
  productInCart,
  isLastProductInCart,
  isForPreview,
  validatedCart,
  isForCombo,
  products,
  cartScreen,
  toggleProductIsFavourited,
}) => {
  const {flatProduct, totalPrice, singlePrice} = productInCart;
  let validatedFlatProduct;

  // if(validatedCart){
  //     validatedFlatProduct = validatedCart.validatedFlatProducts
  //                                   .filter(product => product.product_id == flatProduct.product_id)
  // }

  const handleToggleProductIsFavourited = favouriteId =>
    toggleProductIsFavourited(productInCart.productIndexInCart, favouriteId);

  const getProductPrice = productId =>
    products
      .filter(product => product.flatProduct.product_id === productId)
      .map(product => product.totalPrice)[0];
  return (
    <Wrapper
      key={flatProduct.product_id}
      isLastProductInCart={isLastProductInCart || isForCombo}
      isForPreview={isForPreview}
      isForCombo={isForCombo}
    >
      {!appConfig.isNoImage && <ProductImage src={flatProduct.image_url} />}
      <TitleAndPriceWrapper isNoImage={appConfig.isNoImage}>
        <ProductTitle id="ProductSummaryCart-ProductTitle">
          <TitleIconWrapper>
            <div>{flatProduct.name}</div>
            {cartScreen === 'review' && (
              <FavouriteIconContainer
                flatProduct={productInCart.flatProduct}
                isFavourited={productInCart.favouriteId ? true : false}
                favouriteId={productInCart.favouriteId}
                addedToFavourites={favouriteId =>
                  handleToggleProductIsFavourited(favouriteId)
                }
              />
            )}
          </TitleIconWrapper>
          {validatedCart && flatProduct.quantity > 1 && (
            <span style={{textTransform: 'lowercase'}}>
              {' '}
              x{flatProduct.quantity}
            </span>
          )}
          {!isForPreview && !isForCombo && (
            <CalPriceRow cals isForPreview={isForPreview}>
              <Price id="ProductSummaryCart-Price">
                {numeral(totalPrice).format('$0.00')}
              </Price>
              {flatProduct.calories ? (
                <Fragment>
                  {/* <Dot /> */}
                  <CalorieString
                    id="ProductSummaryCart-Calories"
                    calories={flatProduct.calories.find(
                      calorie => calorie.variant_id === flatProduct.variant_id
                    )}
                  />
                </Fragment>
              ) : (
                <div />
              )}
            </CalPriceRow>
          )}
        </ProductTitle>
      </TitleAndPriceWrapper>
      {isForPreview && !isForCombo && (
        <CalPriceRow cals isForPreview={isForPreview}>
          <Price id="ProductSummaryCart-PricePreview">
            {numeral(totalPrice).format('$0.00')}
          </Price>
          {flatProduct.calories ? (
            <Fragment>
              <Dot />
              <CalorieString
                id="ProductSummaryCart-CaloriesPreview"
                calories={flatProduct.calories.find(
                  calorie => calorie.variant_id === flatProduct.variant_id
                )}
              />
            </Fragment>
          ) : (
            <div />
          )}
        </CalPriceRow>
      )}

      <div style={{gridColumn: '1 / -1'}}>
        {flatProduct.prices.length > 1 && (
          <OptionWrapper isForPreview={isForPreview} isForCombo={isForCombo}>
            <Label id="ProductSummaryCart-Size">Size</Label>
            <Text id="ProductSummaryCart-VariantName">
              {flatProduct.variant_name.toLowerCase()}
            </Text>
          </OptionWrapper>
        )}
        {optionsForProductSummary(flatProduct) &&
          optionsForProductSummary(flatProduct).map((option, index) => {
            const isLastOption =
              optionsForProductSummary(flatProduct).length === index + 1;
            if (option.sub_options.length) {
              return option.sub_options.map(subOption => {
                return (
                  <OptionWrapper
                    isLastOption={isLastOption}
                    key={subOption.option_id}
                    isForPreview={isForPreview}
                    isForCombo={isForCombo}
                  >
                    <Label id="ProductSummaryCart-SubOptionName">
                      {subOption.name}
                    </Label>
                    <Text id="ProductSummaryCart-SubOption">
                      {renderSelectedItemsString(flatProduct, subOption)}
                    </Text>
                  </OptionWrapper>
                );
              });
            } else {
              return (
                <NoteWraper>
                  <NoteLabel>{option.name} -</NoteLabel>
                  <NoteText>
                    {renderSelectedItemsString(flatProduct, option)}
                  </NoteText>
                </NoteWraper>
              );
            }
          })}
        {flatProduct.instruction && (
          <NoteWraper>
            <NoteLabel>Notes -</NoteLabel>
            <NoteText>{flatProduct.instruction}</NoteText>
          </NoteWraper>
        )}
      </div>
      {cartScreen === 'review' && (
        <EditQuantityRow>
          <ProductEditAndQuantityCartContainer productInCart={productInCart} />
        </EditQuantityRow>
      )}
    </Wrapper>
  );
};

export default ProductSummaryCart;
