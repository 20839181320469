import React from 'react';
import {Fragment} from 'react';
import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles';

import ControlledRadio from '../../../components/Form/Radio';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  labelPlacementStart: {
    marginLeft: '0',
  },
});

const HrLine = styled.hr`
  background: #ebebeb;
  margin-bottom: 0;
`;

const TimeSelectionDiv = styled(RadioGroup)`
  margin-bottom: 40px;
  margin-top: 30px;
  @media (max-width: 786px) {
    padding-left: 0;
    width: 90%;
  }
`;

const PayOnArrival = ({
  payOnArrivalPaymentMethod,
  setPayOnArrivalPaymentMethod,
}) => {
  const classes = useStyles();

  const payOnArrivalChangeHandler = e => {
    setPayOnArrivalPaymentMethod(e.target.value);
  };

  return (
    <Fragment>
      <TimeSelectionDiv
        onChange={payOnArrivalChangeHandler}
        value={payOnArrivalPaymentMethod}
      >
        <div>
          <FormControlLabel
            labelPlacement="start"
            label="Credit Card"
            value="creditCard"
            control={<ControlledRadio />}
            classes={classes}
          />
          <HrLine />
        </div>
        <div>
          <FormControlLabel
            labelPlacement="start"
            label="Debit Card"
            value="debitCard"
            control={<ControlledRadio />}
            classes={classes}
          />
          <HrLine />
        </div>
        <div>
          <FormControlLabel
            labelPlacement="start"
            label="Gift Card"
            value="giftCard"
            control={<ControlledRadio />}
            classes={classes}
          />
          <HrLine />
        </div>
        <div>
          <FormControlLabel
            labelPlacement="start"
            label="Google Pay"
            value="googlePay"
            control={<ControlledRadio />}
            classes={classes}
          />
          <HrLine />
        </div>
        <div>
          <FormControlLabel
            labelPlacement="start"
            label="Cash"
            value="cash"
            control={<ControlledRadio />}
            classes={classes}
          />
          <HrLine />
        </div>
      </TimeSelectionDiv>
    </Fragment>
  );
};

export default PayOnArrival;
