const validateCreditCard = (
  values,
  guestPaymentMethod,
  isPostalCodeEnabled,
  isAddressEnabled
) => {
  if (guestPaymentMethod === 'creditCard') {
    let errors = {};
    if (!values.card_number) {
      errors.card_number = 'Required';
    } else if (
      values.card_number.toString().length < 15 ||
      values.card_number.toString().length > 16
    ) {
      errors.card_number = 'Invalid card number';
    }
    if (!values.expiry_month) {
      errors.expiry_month = 'Required';
    }
    if (!values.expiry_year) {
      errors.expiry_year = 'Required';
    }
    if (!values.cvv) {
      errors.cvv = 'Required';
    } else if (values.cvv.toString().length < 3) {
      errors.cvv = 'Invalid CVV';
    }

    if (values.cardholder_name.length < 4) {
      errors.cardholder_name = 'Minimum of 4 characters';
    }
    if (!values.cardholder_name) {
      errors.cardholder_name = 'Required';
    }
    if (isPostalCodeEnabled) {
      if (!values.postal_code) {
        errors.postal_code = 'Required';
      }
      // else if (!POSTAL_CODE_REGEX.test(values.postal_code)) {
      //   errors.postal_code = 'Invalid Postal Code';
      // }
    }
    if (isAddressEnabled) {
      if (!values.street_number) {
        errors.street_number = 'Required';
      }
      if (!values.street_name) {
        errors.street_name = 'Required';
      }
    }
    return errors;
  } else {
    return {};
  }
};

export default validateCreditCard;
