import React, {useEffect, useState, useMemo} from 'react';
import styled from 'styled-components';
import {Title3, Caption1, Body, Subhead} from '../../components/Typography';
import ProductSummaryCartContainer from './ProductSummaryCart/ProductSummaryCartContainer';
import ComboSummaryCartContainer from './ComboSummaryCart/ComboSummaryCartContainer';
import appConfig from '../../../app-config';
import InformationWrapper from '../../components/InformationWrapper';

const ProductSummaryWrapper = styled.div`
  padding-left: ${props => (props.isForPreview ? '0' : '80px')};
  @media (max-width: 786px) {
    padding-left: 0;
  }
`;

const OrderSummaryWrapper = styled.div`
  display: ${props => (props.isFromReviewPage ? 'grid' : 'block')};
  grid-template-columns: ${props =>
    props.isFromReviewPage ? '150px 1fr' : '1fr'};

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: 1fr;
  }
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${props => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const BodyWrapper = styled(Body)`
  text-transform: ${props => props.theme.reviewCheckoutPageTitle};
  font-weight: 600;
`;

const ComboWrapper = styled.div`
  margin-left: -15px;
`;

const OrderSummaryCart = ({
  productsInCart,
  combos,
  isForPreview,
  isFromReviewPage,
  cartScreen,
}) => {
  const isAlcoholicClient = [
    'butchies',
    'moxies',
    'shark',
    'rock_creek',
    'chop',
    'cantina',
    'amano',
    'ground_burger',
    'mercato',
    'smokys',
    'twinfish',
    'fresh',
    'ambiyan',
    'dennys',
    'meVaMe',
    'mevame',
    'isaan_der',
    'janus',
    'The Albany Club'
  ].includes(appConfig.appSource);
  const cartHasAlcohol =
    productsInCart.filter(product => product.flatProduct.is_alcoholic === true)
      .length > 0;

  return (
    <OrderSummaryWrapper isFromReviewPage={isFromReviewPage}>
      {!isForPreview ? (
        <Title3Wrapper id="OrderSummaryCart-OrderSummary">
          Order Summary
        </Title3Wrapper>
      ) : (
        <BodyWrapper id="OrderSummaryCart-OrderSummary">
          Order Summary
        </BodyWrapper>
      )}
      <ProductSummaryWrapper>
        <div>
          {productsInCart.map((productInCart, productInCartIndex) => (
            <ProductSummaryCartContainer
              key={productInCartIndex}
              productInCart={productInCart}
              isLastProductInCart={true}
              cartScreen={cartScreen}
            />
          ))}
          {combos && (
            <ComboWrapper>
              {combos.map((combo, comboIndex) => (
                <ComboSummaryCartContainer
                  key={`cart-combo-${comboIndex}`}
                  comboIndex={comboIndex}
                  combo={combo}
                  cartScreen={cartScreen}
                />
              ))}
            </ComboWrapper>
          )}
        </div>
        {cartHasAlcohol && (
          <InformationWrapper
            text="You must be of legal drinking age to purchase alcohol. Government ID
                is required upon pickup/delivery."
          />
        )}
      </ProductSummaryWrapper>
    </OrderSummaryWrapper>
  );
};

export default OrderSummaryCart;
