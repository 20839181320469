import React, {memo} from 'react';
import styled from 'styled-components';
import {MinusIcon, PlusIcon} from '../../../../components/Icons';
import {EditIcon} from '../../../../components/Icons';
import FavouriteIconContainer from '../../../Favourites/FavouriteIcon/FavouriteIconContainer';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isForPreview && props.isAuthenticated
      ? '1fr  1fr'
      : !props.isForPreview && props.isAuthenticated
      ? '110px  1fr'
      : props.isForPreview && !props.isAuthenticated
      ? '80px 1fr'
      : '110px 1fr'};
  grid-gap: 24px;
  padding: 5px 0;
  align-items: center;
  @media (max-width: 786px) {
    grid-template-columns: ${props =>
      props.isAuthenticated ? '80px 1fr' : '80px 1fr'};
  }
`;

const QuantityWrapper = styled.div`
  justify-self: end;

  display: grid;
  grid-template-columns: 15px 20px 15px;
  grid-gap: 25px;
`;

const QuantityDiv = styled.span`
  color: ${props => props.theme.quantityCartNumber};
`;

const ProductEditAndQuantityCart = ({
  quantity,
  flatProduct,
  handleDecrementProduct,
  handleIncrementProduct,
  handleEditProductConfiguration,

  isForPreview,
  isAuthenticated,
}) => {
  return (
    <Wrapper isForPreview={isForPreview} isAuthenticated={isAuthenticated}>
      <div style={{justifySelf: 'start'}}>
        <EditIcon id="ProductQuantity-EditProduct" onClick={handleEditProductConfiguration} />
      </div>
      <QuantityWrapper>
        <div  id="ProductQuantity-DecrementProduct" onClick={handleDecrementProduct}>
          <MinusIcon />
        </div>
        <QuantityDiv id="ProductQuantity-Quantity">{quantity}</QuantityDiv>
        <div id="ProductQuantity-IncrementProduct" onClick={handleIncrementProduct}>
          <PlusIcon />
        </div>
      </QuantityWrapper>
    </Wrapper>
  );
};

export default memo(ProductEditAndQuantityCart);
