import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './cartActions';
import { editProductConfiguration } from '../ProductCustomizer/productCustomizerActions';
import {
  setAuthenticationModalIsVisible,
  resetGuestSession,
} from '../Auth/authActions';
import { getOffersForReviewScreen } from '../Offers/offersActions';
import productForOrder from './utils/productForOrder';
import Review from './components/Review';
import GuestCheckoutContainer from './GuestCheckout/GuestCheckoutContainer';
import Checkout from './components/Checkout';
import SuccessfulOrder from './components/SuccessfulOrder';
import comboForOrder from './utils/comboForOrder';
import cartForValidation from './utils/cartForValidation';
import {
  ASAP,
  PICKUP,
  DROPOFF,
  DELIVERY,
} from '../OrderSetting/orderSettingTypes';
import appConfigFile from '../../../app-config';
import moment from 'moment';
import Alert from '../Alert/Alert';
import { createAlert } from '../Alert/alertActions';
import useIsFoodRequired from '../../customHooks/useIsFoodRequired';
import useCart from '../../customHooks/useCart';
import StickyErrorMessageBox from '../../components/ErrorMessage/StickyErrorMessageBox';
import { initiateCheckoutAsGuest } from '../Auth/authActions';
import { connectExistingGiftCard } from '../GiftCard/giftCardActions';
import {
  updateGuestPaymentDetails,
  updateGuestGiftCardDetails,
  setTouchedCreditCardDetails,
  resetGuestCreditCardDetails,
  resetGuestGiftCardDetails,
  resetPayPalNonce,
} from '../PaymentMethods/paymentMethodsActions';
import { updateOrderSettingModalIsVisible } from '../OrderSetting/orderSettingActions';
import { navigate } from 'gatsby';
const CartContainer = ({
  cartReducer,
  selectedPickUpLocation,
  customerProfile,
  validateGlobalCart,
  invalidateCart,
  placeOrder,
  paymentMethods,
  isAuthenticated,
  setAuthenticationModalIsVisible,
  setIsForPreview,
  orderSettingReducer,
  orderingClosedInAllLocations,
  getOffersForReviewScreen,
  reviewScreenOffers,
  setCheckoutAsGuestButtonIsVisible,
  initiateCheckoutAsGuest,
  setAccountFoundModalIsVisible,
  selectedDropOffLocation,
  location,
  locationsReducer,
  createAlert,
  is_tip_enabled,
  updateCartScreen,
  globalCart,
  clearErrorMessage,
  setGuestPersonalDetails,
  setShowGuestPersonalDetailsPage,
  validateGuestCart,
  authReducer,
  placeGuestOrder,
  connectExistingGiftCard,
  resetGuestPersonalDetails,
  resetGuestSession,
  resetGuestCreditCardDetails,
  resetGuestGiftCardDetails,
  resetTipPercentage,
  appConfig,
  paymentMethodsReducer,
  deliveryToAddress,
  resetCartErrorMessages,
  payPalNonce,
  resetPayPalNonce,
  updateOrderSettingModalIsVisible,
}) => {
  const {
    products,
    validatedCart,
    orderPlaced,
    tipPercentage,
    specialInstructions,
    requestingPlaceOrder,
    requestingValidateCart,
    placedOrder,
    cartScreen,
    errors,
    showGuestPersonalDetailsPage,
    accountFound,
    accountFoundModalIsVisible,
    guestPersonalDetails,
  } = cartReducer;

  const { selectedPaymentMethod } = paymentMethodsReducer;

  const {
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    interOrderType,
    selectedDateTime,
    parentLocation,
    orderSettingsConfirmed,
  } = orderSettingReducer;
  const deepLink = localStorage.getItem('deep-link-url');
  useEffect(() => {
    if (!orderSettingsConfirmed) {
      navigate('/' + deepLink);
    }
  }, []);
  //const cart = useCart();
  const { isFoodRequired, minFoodPrice } = useIsFoodRequired(products);

  const isTipEnabled =
    appConfig && appConfig.is_tip_enabled ? appConfig.is_tip_enabled : true;

  useEffect(() => {
    if (
      isAuthenticated &&
      (orderSettingReducer.interPickUpDetails ||
        orderSettingReducer.interDeliveryDetails)
    ) {
      getOffersForReviewScreen();
    }
  }, [
    validatedCart,
    interPickUpDetails,
    interDeliveryDetails,
    interDropOffDetails,
    selectedDateTime,
  ]);

  useEffect(() => {
    clearErrorMessage();
    setIsForPreview(false);
    return () => {
      invalidateCart();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orderPlaced]);

  const verifyAlcoholicRestriction = () => {
    if (isFoodRequired) {
      createAlert({
        type: 'error',
        message: `By law, alcohol must be purchased with food (min ${minFoodPrice}) when ordering from restaurants.`,
      });
    }
  };

  useEffect(() => {
    verifyAlcoholicRestriction();
  }, [isFoodRequired, validatedCart]);

  const handlePlaceGuestOrder = (
    paymentDetails,
    giftCard,
    guestPaymentMethod
  ) => {
    const {
      interPickUpDetails,
      interDeliveryDetails,
      interDropOffDetails,
      interOrderType,
    } = orderSettingReducer;

    const location =
      interOrderType === PICKUP && interPickUpDetails
        ? interPickUpDetails
        : interOrderType === DELIVERY &&
          interDeliveryDetails &&
          interDeliveryDetails.deliveryBusiness
        ? interDeliveryDetails.deliveryBusiness
        : interOrderType === DROPOFF && interDropOffDetails
        ? interDropOffDetails
        : null;

    if (location) {
      let orderConfig = {
        business_id: location.id,
        subtotal: validatedCart.subtotal,
        tip: validatedCart.tip,
        tax: validatedCart.tax,
        total: validatedCart.total,
        cart: [
          ...validatedCart.products.map((product) => productForOrder(product)),
          ...comboForOrder(validatedCart.combos || []),
        ],
        special_instruction: specialInstructions,
      };

      if (orderSettingReducer.interOrderType === DROPOFF) {
        let dropOffLocation = [...selectedDropOffLocation];
        const dropOffLocationObject = dropOffLocation
          .reverse()
          .reduce((obj, selection) => {
            return {
              id: selection.id,
              option: { ...obj },
            };
          }, {});

        orderConfig = {
          ...orderConfig,
          drop_off_location: dropOffLocationObject,
        };
      } else if (orderSettingReducer.interOrderType === DELIVERY) {
        orderConfig = {
          ...orderConfig,
          delivery_address: {
            line1: deliveryToAddress.line1,
            line2: deliveryToAddress.line2,
            city: deliveryToAddress.city,
            state: deliveryToAddress.state,
            zip: deliveryToAddress.zip,
            country: deliveryToAddress.country,
            type: deliveryToAddress.type,
            is_preferred: deliveryToAddress.is_preferred,
            details: deliveryToAddress.details,
          },
        };
      }

      if (
        appConfigFile.isFutureOrderDelivery ||
        appConfigFile.isFutureOrderPickup ||
        appConfigFile.isFutureOrderDropOff
      ) {
        if (
          orderSettingReducer.selectedDateTime.radioValue &&
          orderSettingReducer.selectedDateTime.radioValue.value !== ASAP &&
          orderSettingReducer.selectedDateTime.radioValue.value !== null
        ) {
          let orderTimeModified = orderSettingReducer.dateTimeToShow.value;
          orderConfig = {
            ...orderConfig,
            order_date: moment(orderTimeModified, 'ddd MMM DD YYYY LT').format(
              'YYYY-MM-DD HH:mm:ss'
            ),
          };
        }
      }
      placeGuestOrder(orderConfig, paymentDetails, giftCard);
    }
  };

  const handlePlaceOrder = () => {
    let orderConfig = {
      business_id:
        orderSettingReducer.interOrderType === PICKUP
          ? selectedPickUpLocation.id
          : orderSettingReducer.interOrderType === DROPOFF
          ? interDropOffDetails.id
          : orderSettingReducer.interDeliveryDetails.deliveryBusiness.id, // latitude: selectedPickUpLocation.latitude, // longitude: selectedPickUpLocation.longitude,
      subtotal: validatedCart.subtotal,
      tip: validatedCart.tip,
      total_credit_applied: validatedCart.total_credit_applied,
      total: validatedCart.total,
      cart: [
        ...validatedCart.products.map((product) => productForOrder(product)),
        ...comboForOrder(validatedCart.combos || []),
      ],
      special_instruction: specialInstructions,
    };

    if (orderSettingReducer.interOrderType === DROPOFF) {
      let dropOffLocation = [...interDropOffDetails.finalDropOffLocation];
      const dropOffLocationObject = dropOffLocation
        .reverse()
        .reduce((obj, selection) => {
          return {
            id: selection.id,
            option: { ...obj },
          };
        }, {});

      orderConfig = {
        ...orderConfig,
        drop_off_location: dropOffLocationObject,
      };
    }

    if (
      appConfigFile.isFutureOrderDelivery ||
      appConfigFile.isFutureOrderPickup ||
      appConfigFile.isFutureOrderDropOff
    ) {
      if (
        orderSettingReducer.selectedDateTime.radioValue &&
        orderSettingReducer.selectedDateTime.radioValue.value == 'schedule'
      ) {
        let orderTimeModified = orderSettingReducer.dateTimeToShow.value;
        orderConfig = {
          ...orderConfig,
          order_date: moment(orderTimeModified, 'ddd MMM DD YYYY LT').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
        };
      }
    }
    placeOrder(orderConfig);
  };

  const orderMethod =
    interOrderType === PICKUP
      ? interPickUpDetails
      : interOrderType === DELIVERY
      ? interDeliveryDetails
      : interOrderType === DROPOFF
      ? interDropOffDetails
      : interPickUpDetails;

  useEffect(() => {
    if (showGuestPersonalDetailsPage) {
      updateCartScreen('guest-order');
    }
  }, [showGuestPersonalDetailsPage]);

  useEffect(() => {
    return () => {
      setShowGuestPersonalDetailsPage(false);
    };
  }, []);
  return orderSettingReducer.orderSettingsConfirmed ? (
    <Fragment>
      {errors && Object.keys(errors).length > 0 && (
        <StickyErrorMessageBox errors={errors} />
      )}
      {orderPlaced && (customerProfile || guestPersonalDetails) ? (
        <SuccessfulOrder
          userEmail={
            !isAuthenticated
              ? guestPersonalDetails.email
              : customerProfile.email
          }
          placedOrder={placedOrder}
          isOrderStatusEnabled={appConfigFile.isOrderStatusEnabled}
          selectedChildLocation={orderMethod}
          orderDetails={orderMethod}
          isAuthenticated={isAuthenticated}
          orderSettingReducer={orderSettingReducer}
          resetGuestPersonalDetails={resetGuestPersonalDetails}
          resetGuestSession={resetGuestSession}
          resetGuestCreditCardDetails={resetGuestCreditCardDetails}
          resetGuestGiftCardDetails={resetGuestGiftCardDetails}
          resetPayPalNonce={resetPayPalNonce}
          parentLocation={parentLocation}
          appConfig={appConfig}
          updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
          deliveryToAddress={deliveryToAddress}
        />
      ) : cartScreen === 'guest-order' && !isAuthenticated ? (
        <GuestCheckoutContainer
          id="CartContainer-GuestCheckoutContainer"
          setAuthenticationModalIsVisible={setAuthenticationModalIsVisible}
          initiateCheckoutAsGuest={initiateCheckoutAsGuest}
          accountFound={accountFound}
          setAccountFoundModalIsVisible={setAccountFoundModalIsVisible}
          accountFoundModalIsVisible={accountFoundModalIsVisible}
          validatedCart={validatedCart}
          handleValidateCart={validateGlobalCart}
          isAuthenticated={isAuthenticated}
          guestPersonalDetails={guestPersonalDetails}
          setGuestPersonalDetails={setGuestPersonalDetails}
          setShowGuestPersonalDetailsPage={setShowGuestPersonalDetailsPage}
          showGuestPersonalDetailsPage={showGuestPersonalDetailsPage}
          handleValidateGuestCart={validateGlobalCart}
          orderSettingReducer={orderSettingReducer}
          locationsReducer={locationsReducer}
          handlePlaceGuestOrder={handlePlaceGuestOrder}
          authReducer={authReducer}
          connectExistingGiftCard={connectExistingGiftCard}
          paymentMethodsReducer={paymentMethodsReducer}
          updateGuestPaymentDetails={updateGuestPaymentDetails}
          updateGuestGiftCardDetails={updateGuestGiftCardDetails}
          setTouchedCreditCardDetails={setTouchedCreditCardDetails}
          cartReducer={cartReducer}
          tipPercentage={tipPercentage}
          resetTipPercentage={resetTipPercentage}
          updateCartScreen={updateCartScreen}
          cartScreen={cartScreen}
          is_tip_enabled={isTipEnabled}
          validateGlobalCart={validateGlobalCart}
          appConfig={appConfig}
          selectedPaymentMethod={selectedPaymentMethod}
          requestingValidateCart={requestingValidateCart}
        />
      ) : cartScreen === 'review' ? (
        <Review
          id="CarContainer-Review"
          cartScreen={cartScreen}
          updateCartScreen={updateCartScreen}
          validatedCart={validatedCart}
          handleValidateCart={validateGlobalCart}
          selectedPickUpLocation={selectedPickUpLocation}
          handlePlaceOrder={handlePlaceOrder}
          invalidateCart={invalidateCart}
          paymentMethods={paymentMethods}
          disableCheckoutButton={
            (!validatedCart &&
              !cartReducer.products.length &&
              !cartReducer.combos.length) ||
            (isAuthenticated &&
              interOrderType === PICKUP &&
              interPickUpDetails &&
              !interPickUpDetails.id) ||
            (isAuthenticated &&
              interDeliveryDetails &&
              interOrderType === DELIVERY &&
              !interDeliveryDetails.id) ||
            (isAuthenticated &&
              interDropOffDetails &&
              interOrderType === DROPOFF &&
              !interDropOffDetails.id)
          }
          isAuthenticated={isAuthenticated}
          setAuthenticationModalIsVisible={setAuthenticationModalIsVisible}
          requestingPlaceOrder={requestingPlaceOrder}
          requestingValidateCart={requestingValidateCart}
          orderSettingReducer={orderSettingReducer}
          orderingClosedInAllLocations={orderingClosedInAllLocations}
          reviewScreenOffers={reviewScreenOffers}
          productsInCart={cartReducer.products}
          setCheckoutAsGuestButtonIsVisible={setCheckoutAsGuestButtonIsVisible}
          isFoodRequired={isFoodRequired}
          locationsReducer={locationsReducer}
          is_tip_enabled={isTipEnabled}
          errors={errors}
        />
      ) : cartScreen === 'checkout' ? (
        <Checkout
          cartScreen={cartScreen}
          updateCartScreen={updateCartScreen}
          validatedCart={validatedCart}
          handleValidateCart={validateGlobalCart}
          selectedPickUpLocation={selectedPickUpLocation}
          handlePlaceOrder={handlePlaceOrder}
          invalidateCart={invalidateCart}
          paymentMethods={paymentMethods}
          disableCheckoutButton={
            !validatedCart &&
            !cartReducer.products.length &&
            !cartReducer.combos.length // || orderingClosedInAllLocations
          }
          isAuthenticated={isAuthenticated}
          setAuthenticationModalIsVisible={setAuthenticationModalIsVisible}
          requestingPlaceOrder={requestingPlaceOrder}
          requestingValidateCart={requestingValidateCart}
          orderSettingReducer={orderSettingReducer}
          orderingClosedInAllLocations={orderingClosedInAllLocations}
          reviewScreenOffers={reviewScreenOffers}
          locationsReducer={locationsReducer}
          productsInCart={cartReducer.products}
          is_tip_enabled={isTipEnabled}
          errors={errors}
          resetCartErrorMessages={resetCartErrorMessages}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      ) : deepLink ? (
        navigate('/' + deepLink)
      ) : (
        navigate('/')
      )}
      <Alert />
    </Fragment>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    appConfig: state.authReducer.appConfig,
    cartReducer: state.cartReducer,
    selectedPickUpLocation: state.orderSettingReducer.interPickUpDetails,
    dropOffDetails: state.orderSettingReducer.dropOffDetails,
    orderSettingReducer: state.orderSettingReducer,
    paymentMethodsReducer: state.paymentMethodsReducer,
    customerProfile: state.profileSettingsReducer.customerProfile,
    paymentMethods: state.paymentMethodsReducer.paymentMethods,
    isAuthenticated: state.authReducer.isAuthenticated,
    orderingClosedInAllLocations:
      state.locationsReducer.orderingClosedInAllLocations,
    reviewScreenOffers: state.offersReducer.reviewScreenOffers,
    selectedDropOffLocation:
      state.dropOffLocationReducer.selectedDropOffLocation,
    locationsReducer: state.locationsReducer,
    globalCart: state.cartReducer.cart, //GLOBAL CART
    deliveryToAddress: state.deliveryAddressesReducer.deliveryToAddress,
    payPalNonce: state.paymentMethodsReducer.payPalNonce,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  editProductConfiguration,
  setAuthenticationModalIsVisible,
  getOffersForReviewScreen,
  createAlert: (props) => createAlert(props),
  initiateCheckoutAsGuest,
  createAlert: (props) => createAlert(props),
  connectExistingGiftCard,
  resetGuestSession,
  updateGuestPaymentDetails,
  updateGuestGiftCardDetails,
  setTouchedCreditCardDetails,
  resetGuestCreditCardDetails,
  resetGuestGiftCardDetails,
  resetPayPalNonce,
  updateOrderSettingModalIsVisible,
})(CartContainer);
