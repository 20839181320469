import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import ControlledRadio from '../../../components/Form/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import {
  PICKUP,
  DROPOFF,
  DELIVERY,
} from '../../OrderSetting/orderSettingTypes';
import appConfig from '../../../../app-config';
import { validateGlobalCart } from '../../Cart/cartActions';
import { Title3 } from '../../../components/Typography';
import {
  updateIsPayOnArrivalSelect,
  updateGuestGiftCardDetails,
} from '../paymentMethodsActions';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  labelPlacementStart: {
    marginLeft: '0',
  },
});

const PaymentTypeWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 560px;
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 21px;
    grid-template-rows: auto auto;
  }
`;

const HrLine = styled.hr`
  background: #ebebeb;
  margin-bottom: 0;
`;

const TimeSelectionDiv = styled(RadioGroup)`
  padding-left: 80px;
  @media (max-width: 786px) {
    padding-left: 0;
    width: 90%;
  }
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;

  @media (max-width: 786px) {
    width: max-content;
  }

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;
const PayOnArrivalDes = styled.div`
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  margin-top: 15px;
  text-align: left;
`;
const PaymentType = ({
  guestPaymentMethod,
  changeGuestPaymentMethod,
  interOrderType,
  interPickUpDetails,
  interDeliveryDetails,
  interDropOffDetails,
  updateIsPayOnArrivalSelect,
  updateGuestGiftCardDetails,
  validateGlobalCart,
  isPayOnArrivalSelected,
  authAppConfig,
  getClientToken,
  selectedLocation,
}) => {
  const isPayOnArrival =
    interOrderType === PICKUP && selectedLocation
      ? selectedLocation.accepted_order_types_config?.pickup
          .is_loyalty_only_enabled
      : interOrderType === DELIVERY && selectedLocation
      ? selectedLocation.accepted_order_types_config?.delivery
          .is_loyalty_only_enabled
      : interOrderType === DROPOFF && selectedLocation
      ? selectedLocation.accepted_order_types_config?.drop_off
          .is_loyalty_only_enabled
      : null;

  const classes = useStyles();
  const paymentTypeChangeHandler = (e) => {
    changeGuestPaymentMethod(e.target.value);
    if (e.target.value === 'poa') {
      updateIsPayOnArrivalSelect(true);
      updateGuestGiftCardDetails(null);
      validateGlobalCart();
    } else if (e.target.value === 'creditCard') {
      updateGuestGiftCardDetails(null);
      updateIsPayOnArrivalSelect(false);
    } else if (e.target.value === 'paypal') {
      getClientToken();
      updateIsPayOnArrivalSelect(false);
    }
  };
  return (
    <Fragment>
      <PaymentTypeWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title3Wrapper>Payment Type</Title3Wrapper>
        </div>
        <TimeSelectionDiv
          name="paymentType"
          onChange={paymentTypeChangeHandler}
          value={guestPaymentMethod}
        >
          <div>
            <FormControlLabel
              labelPlacement="start"
              label="Credit Card"
              value="creditCard"
              control={<ControlledRadio />}
              classes={classes}
            />
            <HrLine />
          </div>
          {appConfig.isGiftCardEnabled && (
            <div>
              <FormControlLabel
                labelPlacement="start"
                label="Gift Card"
                value="giftCard"
                control={<ControlledRadio />}
                classes={classes}
              />
            </div>
          )}
          {authAppConfig?.payment_methods?.includes('paypal') && (
            <div>
              <FormControlLabel
                labelPlacement="start"
                label="PayPal"
                value="paypal"
                control={<ControlledRadio />}
                classes={classes}
              />
            </div>
          )}
          <HrLine />
          {isPayOnArrival && (
            <div>
              <FormControlLabel
                labelPlacement="start"
                label={
                  appConfig.appSource === 'feastify'
                    ? 'Pay with Card in Person'
                    : 'Pay on Arrival'
                }
                value="poa"
                control={<ControlledRadio />}
                classes={classes}
              />
              <HrLine />
            </div>
          )}
          {isPayOnArrivalSelected && appConfig.appSource === 'feastify' && (
            <PayOnArrivalDes>
              Tips are not included in the total. Feeling generous? You can tip
              in person.
            </PayOnArrivalDes>
          )}
        </TimeSelectionDiv>
      </PaymentTypeWrapper>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isPayOnArrivalSelected: state.paymentMethodsReducer.isPayOnArrivalSelected,
  selectedLocation: state.orderSettingReducer.selectedLocation,
});
export default connect(mapStateToProps, {
  updateIsPayOnArrivalSelect,
  updateGuestGiftCardDetails,
  validateGlobalCart,
})(PaymentType);
