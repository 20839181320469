import React from 'react';
import {connect} from 'react-redux';
import {toggleProductIsFavourited} from '../../Cart/cartActions';
import ProductSummaryCart from './ProductSummaryCart';

const ProductSummaryCartContainer = ({
  productInCart,
  isLastProductInCart,
  isForPreview,
  validatedCart,
  isForCombo,
  products,
  cartScreen,
  toggleProductIsFavourited,
}) => {
  return (
    <ProductSummaryCart
      productInCart={productInCart}
      isLastProductInCart={isLastProductInCart}
      isForPreview={isForPreview}
      validatedCart={validatedCart}
      isForCombo={isForCombo}
      products={products}
      cartScreen={cartScreen}
      toggleProductIsFavourited={toggleProductIsFavourited}
    />
  );
};

const mapStateToProps = state => ({
  validatedCart: state.cartReducer.validatedCart,
  isForPreview: state.cartReducer.isForPreview,
  products: state.cartReducer.products,
});

export default connect(mapStateToProps, {toggleProductIsFavourited})(
  ProductSummaryCartContainer
);
