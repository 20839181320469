import React from "react";
import styled from "styled-components";
import numeral from "numeral";
import { Headline } from "../../../components/Typography";
import ProductSummaryCartContainer from "../ProductSummaryCart/ProductSummaryCartContainer";
import { EditIcon } from "../../../components/Icons";
import RemoveIcon from "../../../components/Icons/RemoveIcon";
import flattenProduct from "../../../utils/flattenProduct";
import ComboSummary from "../../OrderSummary/ComboSummary/ComboSummary";

const Wrapper = styled.div`
  grid-gap: 8px;
  margin: 24px 0;
  display: grid;
  grid-template-columns: 6px auto;
`;

const RightBorder = styled.div`
  width: 6px;
  background-color: ${(props) => props.theme.reviewOrderButtonBackgroundColor};
  border-radius: 2px;
`;

const ContentWrapper = styled.div`
  border-bottom: ${(props) =>
    `1px solid ${props.theme.productSummaryBorderColor}`};
  padding-bottom: 12px;
`;

const TitleAndPriceWrapper = styled.div``;

const ComboTitle = styled(Headline)``;

const Price = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-top: 4px;
  color: ${(props) => props.theme.darkGray};
`;

const ComboGroupWrapper = styled.div`
  margin-top: 10px;
`;

const ActionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const RemoveIconWrapper = styled.span`
  justify-self: right;
`;
const NoteWraper = styled.div`
  width: 100%;
  margin: 0 5px 20px 0;
`;
const NoteLabel = styled.span`
  width: 100%;
  height: 17px;
  margin: 0 4px 15px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: var(--colors-basic-dark);
`;
const NoteText = styled.span`
  width: 100%;
  height: 17px;
  white-space: pre-line;
  margin: 0 0 0 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: var(--colors-basic-dark);
`;

const ComboSummaryCart = ({
  combo,
  editCombo,
  removeCombo,
  validatedCart,
  cartScreen,
}) => {
  const validatedCartTotalPrice = (combo) => {
    let totalPrice = combo.price?.amount;
    if (validatedCart && combo.products) {
      combo.products.map((product) => {
        if (product.options) {
          product.options.map((option) => {
            if (option.items)
              option.items.map((item) => {
                totalPrice += item.price;
              });
          });
        } else {
          totalPrice += product.price;
        }
      });
    }
    return totalPrice.toFixed(2);
  };
  return (
    <Wrapper>
      <RightBorder />
      <ContentWrapper>
        {cartScreen === "review" && (
          <div>
            <TitleAndPriceWrapper>
              <ComboTitle id="ComboSummary-ComboName">{combo.name}</ComboTitle>
              {combo.totalPrice && (
                <Price id="ComboSummary-Price">
                  {numeral(combo.totalPrice).format("$0.00")}
                </Price>
              )}
            </TitleAndPriceWrapper>
            {cartScreen === "review" &&
              combo.combo_item_groups.map((group, groupIndex) => {
                if (!group.productCustomizer) return null;
                return (
                  <ComboGroupWrapper>
                    <ProductSummaryCartContainer
                      productInCart={group.productCustomizer}
                      key={`cart-combo-product-${groupIndex}`}
                      isForCombo={true}
                    />
                  </ComboGroupWrapper>
                );
              })}
            {combo.instruction && (
              <NoteWraper>
                <NoteLabel>Notes -</NoteLabel>
                <NoteText>{combo.instruction}</NoteText>
              </NoteWraper>
            )}
            {cartScreen === "review" && (
              <ActionRow>
                <EditIcon
                  id="ComboSummary-EditCombo"
                  onClick={() => editCombo()}
                />
                <RemoveIconWrapper>
                  <RemoveIcon
                    id="ComboSummary-RemoveCombo"
                    onClick={() => removeCombo()}
                  />
                </RemoveIconWrapper>
              </ActionRow>
            )}
          </div>
        )}
        {cartScreen !== "review" && (
          <div>
            <TitleAndPriceWrapper>
              <ComboTitle id="ComboSummary-ComboName">{combo.name}</ComboTitle>
              {combo.is_dollar_discount && (
                <Price id="ComboSummary-Price">
                  {cartScreen !== "review"
                    ? numeral(
                        validatedCart
                          ? validatedCartTotalPrice(combo)
                          : combo.totalPrice
                      ).format("$0.00")
                    : numeral(combo.totalPrice).format("$0.00")}
                </Price>
              )}
            </TitleAndPriceWrapper>
            {validatedCart &&
              combo.products.map((product, productIndex) => {
                return (
                  <ComboGroupWrapper>
                    <ProductSummaryCartContainer
                      productInCart={{
                        flatProduct: flattenProduct(product),
                        singlePrice: 0,
                        totalPrice: 0,
                      }}
                      key={`cart-combo-product-${productIndex}`}
                      isForCombo={true}
                    />
                  </ComboGroupWrapper>
                );
              })}
            {combo.instruction && (
              <NoteWraper>
                <NoteLabel>Notes -</NoteLabel>
                <NoteText>{combo.instruction}</NoteText>
              </NoteWraper>
            )}
          </div>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ComboSummaryCart;
