import  React, {useEffect} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import * as actions from './UpsellActions';
import {getProductToConfigure} from '../ProductCustomizer/productCustomizerActions';
import Upsell from './Upsell';

const Wrapper = styled.div`
  display: grid;
`;

const UpsellContainer = ({
    getUpsell, 
    upsellProducts, 
    businessId, 
    getProductToConfigure,
    productsInCart,
    requestingUpsell,
}) => {

    useEffect (() => {
        getUpsell(businessId)
    },[])
    
    return (
        !requestingUpsell > 0 && productsInCart.length > 0 &&
        <Wrapper>
            <Upsell upsellProducts = {upsellProducts}
                    getProductToConfigure = {getProductToConfigure}
                    businessId = {businessId}
                />
        </Wrapper>
    );
}

const mapStateToProps = state => ({
    requestingUpsell: state.upsellReducer.requestingUpsell,
    upsellProducts: state.upsellReducer.upsellProducts,
    productsInCart: state.cartReducer.products
  });


export default connect(mapStateToProps,{...actions, getProductToConfigure})(UpsellContainer);