import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Title1 from '../../../components/Typography/Title1';
import Body from '../../../components/Typography/Body';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { navigate } from 'gatsby';

const MainDiv = styled.div`
  display: grid;
  grid-template-rows: 0.3fr 1fr 1fr;
  grid-gap: 25px;
  margin: 10px;
`;

const ButtonDiv = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
`;

const CancelWrapper = styled.div``;

const Cancel = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  position: absolute;
  right: 8px;
  top: 10px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
  }
`;

function FoodRequiredModal(props) {
  const deepLink = localStorage.getItem('deep-link-url');
  const { open, close } = props;
  return (
    <Dialog disableBackdropClick open={open}>
      <MainDiv>
        <div>
          <Title1>Something is missing</Title1>
          <CancelWrapper>
            <Cancel
              onClick={() => {
                close();
              }}
            >
              <i className="fal fa-times" />
            </Cancel>
          </CancelWrapper>
        </div>
        <Body>
          By law, alcohol must be purchased with food when ordering from
          restaurants.
        </Body>
        <ButtonDiv>
          <PrimaryButton
            onClick={() => {
              if (deepLink) {
                navigate('/' + deepLink);
              } else {
                navigate('/');
              }
              close();
            }}
          >
            Go to Menu
          </PrimaryButton>
        </ButtonDiv>
      </MainDiv>
    </Dialog>
  );
}

export default FoodRequiredModal;
