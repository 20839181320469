import React from 'react';
import styled from 'styled-components';
import {Title3} from '../../components/Typography';
import UpsellSlider from './components/UpsellSlider';

const Wrapper = styled.div`
  // width: 700px;
  // overflow-x: auto;

  // @media (max-width: 480px) {
  //   width: unset !important;
  // }
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${props => props.theme.reviewCheckoutPageTitle};
  margin-bottom: 30px;
  font-weight: 600;
  width: max-content;

  @media (max-width: 786px) {
    width: max-content;
  }
  :after {
    content: '';
    display: block;
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
    width: calc(100% + 2px);
  }
`;

const Upsell = ({upsellProducts, getProductToConfigure}) => {
  return (
    <Wrapper>
      <Title3Wrapper>You May Also Like</Title3Wrapper>
      <UpsellSlider
        upsellProducts={upsellProducts}
        getProductToConfigure={getProductToConfigure}
      />
    </Wrapper>
  );
};

export default Upsell;
