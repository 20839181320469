import React, {useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import OrderSummaryCart from './OrderSummaryCart';
import EmptyCart from './EmptyCart';
import flattenProduct from '../../utils/flattenProduct';

const OrderSummaryCartContainer = ({
  productsInCart,
  combos,
  isForPreview,
  isFromReviewPage,
  setCouponModalIsVisible,
  setCreditModalIsVisible,
  validatedCart,
  orderSettingReducer,
  setCartHasOnlyAlcohol,
  cartScreen,
}) => {
  const {interPickUpDetails, interDeliveryDetails} = orderSettingReducer;

  const {alcoholic_restriction} = interPickUpDetails
    ? interPickUpDetails
    : interDeliveryDetails && interDeliveryDetails.deliveryBusiness
    ? interDeliveryDetails.deliveryBusiness
    : {
        alcoholic_restriction: {
          is_alcoholic_restriction_enabled: false,
          min_food_total: 0,
        },
      };
  const validatedProducts = () => {
    let products = null;
    if (cartScreen !== 'review' && validatedCart) {
      products = validatedCart.validatedFlatProducts.map(product => ({
        flatProduct: product,
        singlePrice: product.singlePrice,
        totalPrice: product.totalPrice,
      }));
    } else {
      products = productsInCart;
    }
    return products;
  };
  const validatedCombos = () => {
    let updatedCombos = null;
    if (cartScreen !== 'review' && validatedCart) {
      updatedCombos = validatedCart.combos;
    } else {
      updatedCombos = combos;
    }
    return updatedCombos;
  };
  if (validatedProducts()?.length || validatedCombos()?.length) {
    return (
      <OrderSummaryCart
        productsInCart={validatedProducts()}
        combos={validatedCombos()}
        isForPreview={isForPreview}
        cartIsEmpty={false}
        isFromReviewPage={isFromReviewPage}
        setCartHasOnlyAlcohol={bool =>
          setCartHasOnlyAlcohol(
            bool && alcoholic_restriction && alcoholic_restriction.is_alcoholic_restriction_enabled
          )
        }
        cartScreen={cartScreen}
      />
    );
  } else {
    return <EmptyCart isForPreview={isForPreview} />;
  }
};

const mapStateToProps = state => ({
  productsInCart: state.cartReducer.products,
  combos: state.cartReducer.combos,
  isForPreview: state.cartReducer.isForPreview,
  validatedCart: state.cartReducer.validatedCart,
  products: state.productCustomizerReducer,
  orderSettingReducer: state.orderSettingReducer,
  cartScreen: state.cartReducer.cartScreen,
});

export default connect(
  mapStateToProps
  // {setIsForPreview}
)(OrderSummaryCartContainer);
