import React from 'react';
import {connect} from 'react-redux';
import {changeSpecialInstructions} from '../cartActions';
import SpecialInstructions from './SpecialInstructions';

const SpecialInstructionsContainer = ({
  specialInstructions,
  changeSpecialInstructions,
  isForReviewPage,
}) => (
  <SpecialInstructions
    specialInstructions={specialInstructions}
    changeSpecialInstructions={changeSpecialInstructions}
    isForReviewPage={isForReviewPage}
  />
);

const mapStateToProps = state => ({
  specialInstructions: state.cartReducer.specialInstructions,
});

export default connect(mapStateToProps, {changeSpecialInstructions})(
  SpecialInstructionsContainer
);
