import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import numeral from 'numeral';

const Wrapper = styled.div`
  margin-top: 10px;
`;

const SliderInner = styled.div`
  &:focus {
    outline: none;
  }
`;

const ProductWrapper = styled.div`
  width: 150px;
  // margin: 5px;
  display: grid;
  grid-gap: 5px;
  margin: auto;
  cursor: ${props => (props.isAvailable ? 'pointer' : 'default')};

  @media screen and (max-width: 767px) {
    width: 70px;
  }
`;

const Image = styled.img`
  // width: 130px;
  // height: 130px;
  border-radius: 50%;
  margin: auto;
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
`;

const ProductName = styled.div`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  // text-transform: capitalize;
  font-family: ${props => props.theme.primaryFontFontFamily};
  color: ${props =>
    props.isAvailable
      ? props.theme.black
      : props.theme.unavailableProductColor};
`;

const ProductPrice = styled.div`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: ${props =>
    props.isAvailable
      ? props.theme.darkGray
      : props.theme.unavailableProductColor};
`;

const NextArrow = styled.div`
  @media (max-width: 480px) {
    display: none !important;
  }
`;

const PrevArrow = styled.div`
  @media (max-width: 480px) {
    display: none !important;
  }
`;

function CustomNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <NextArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#fff',
        borderRadius: '50%',
        border: 'none',
        height: '40px',
        width: '40px',
        boxShadow: ' 0 2px 5px 0 rgba(59, 76, 112, 0.24)',
        // right: '-70px',
        opacity: 1,
        fontSize: '16px',
        lineHeight: '40px',
        textAlign: 'center',
        zIndex: '1',
        top: '34%'
      }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <PrevArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#fff',
        borderRadius: '50%',
        border: 'none',
        height: '40px',
        width: '40px',
        boxShadow: ' 0 2px 5px 0 rgba(59, 76, 112, 0.24)',
        // left: '5px',
        opacity: 1,
        lineHeight: '40px',
        textAlign: 'center',
        zIndex: '1',
        top: '34%'
      }}
      onClick={onClick}
    />
  );
}

const UpsellSlider = ({upsellProducts, getProductToConfigure}) => {
  var slideSettings = {
    dots: false,
    infinite: false,
    speed: 600,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    // swipeToSlide: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    responsive: [
      // {
      //   breakpoint: 1400,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     nextArrow: <CustomNextArrow />,
      //     prevArrow: <CustomPrevArrow />,
      //   },
      // },
      // {
      //   breakpoint: 1200,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     initialSlide: 0,
      //     dots: false,
      //     infinite: false,
      //     speed: 600,
      //     arrows: true,
      //     // className: 'slider variable-width',
      //     // variableWidth: true,
      //     nextArrow: <CustomNextArrow />,
      //     prevArrow: <CustomPrevArrow />,
      //   },
      // },
      // {
      //   breakpoint: 1100,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     initialSlide: 0,
      //     dots: false,
      //     infinite: false,
      //     speed: 600,
      //     arrows: true,
      //     nextArrow: <CustomNextArrow />,
      //     prevArrow: <CustomPrevArrow />,
      //   },
      // },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 3,
          // initialSlide: 0,
          // dots: false,
          // infinite: false,
          // speed: 600,
          arrows: true,
          swipeToSlide: false,
          nextArrow: <CustomNextArrow />,
          prevArrow: <CustomPrevArrow />,
        },
      },
      {
        breakpoint: 786,
        settings: {
          // slidesToShow: 3.5,
          // slidesToScroll: 3,
          // initialSlide: 0,
          // dots: false,
          // infinite: false,
          // speed: 600,
          arrows: false,
          swipeToSlide: true,
          // nextArrow: <CustomNextArrow />,
          // prevArrow: <CustomPrevArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          initialSlide: 0,
          // dots: false,
          infinite: false,
          speed: 600,
          arrows: false,
          swipeToSlide: true,
        },
      },
    ],
  };
  return (
    <Wrapper className="slider-wrapper">
      <Slider {...slideSettings}>
        {upsellProducts.map(product => (
          <SliderInner key={product.product_id}>
            <ProductWrapper
              onClick={() =>
                product.is_product_available &&
                getProductToConfigure(product.product_id)
              }
              isAvailable={product.is_product_available}
            >
              <Image
                src={product.image_url}
                isAvailable={product.is_product_available}
              />
              <ProductName isAvailable={product.is_product_available}>
                {product.name}
              </ProductName>
              <ProductPrice isAvailable={product.is_product_available}>
                {numeral(product.price).format('$0.00')}
              </ProductPrice>
            </ProductWrapper>
          </SliderInner>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default UpsellSlider;
